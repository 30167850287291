/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SeasonsRecordCommentsRecordCommentModel } from './seasons-record-comments-record-comment-model';
import { SeasonsPlansPlanLightModel } from './seasons-plans-plan-light-model';
import { IncentiveRecordsAssignmentLetterModel } from './incentive-records-assignment-letter-model';
import { IncentiveEmployeesEmployeeSnapshotModel } from './incentive-employees-employee-snapshot-model';
import { SeasonsSeasonsSeasonLightModel } from './seasons-seasons-season-light-model';
import { IncentiveRecordsRecordLetterStateModel } from './incentive-records-record-letter-state-model';
import { SeasonsMembersMemberLightModel } from './seasons-members-member-light-model';

export interface IncentiveRecordsMonitoringRecordModel {
  id?: string;
  assignmentId?: string | null;
  reassignmentId?: string | null;
  memberId?: string | null;
  creationContext?: IncentiveRecordsMonitoringRecordModelCreationContextEnum;
  state?: IncentiveRecordsMonitoringRecordModelStateEnum;
  startDate?: string;
  endDate?: string;
  member?: SeasonsMembersMemberLightModel;
  plan?: SeasonsPlansPlanLightModel;
  season?: SeasonsSeasonsSeasonLightModel;
  letters?: Array<IncentiveRecordsAssignmentLetterModel> | null;
  initialSnapshot?: IncentiveEmployeesEmployeeSnapshotModel;
  durationInCalenderDays?: number;
  proration?: number;
  proratedTiaLocalCurrency?: number;
  proratedTiaEuro?: number;
  ippAchievementValue?: number;
  payoutAmount?: number;
  payoutAmountInEuro?: number;
  totalTargetAchievement?: number;
  unitTargetAchievement?: number;
  unitTargetPayoutAmount?: number;
  unitTargetPayoutAmountInEuro?: number;
  individualMultiplierValue?: number;
  comments?: Array<SeasonsRecordCommentsRecordCommentModel> | null;
  payoutState?: IncentiveRecordsMonitoringRecordModelPayoutStateEnum | null;
  payoutDate?: string;
  exportState?: IncentiveRecordsMonitoringRecordModelExportStateEnum;
  definedPayoutPercentage?: number | null;
  recordLetterStates?: Array<IncentiveRecordsRecordLetterStateModel> | null;
  processState?: IncentiveRecordsMonitoringRecordModelProcessStateEnum;
}
export enum IncentiveRecordsMonitoringRecordModelCreationContextEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5
}
export enum IncentiveRecordsMonitoringRecordModelStateEnum {
  Active = 'Active',
  Draft = 'Draft',
  Outdated = 'Outdated',
  Irrelevant = 'Irrelevant'
}
export enum IncentiveRecordsMonitoringRecordModelPayoutStateEnum {
  StandardPayout = 'StandardPayout',
  FullPayout = 'FullPayout',
  DefinedPayoutEndOfFiscalYear = 'DefinedPayoutEndOfFiscalYear',
  NoPayout = 'NoPayout',
  Irrelevant = 'Irrelevant'
}
export enum IncentiveRecordsMonitoringRecordModelExportStateEnum {
  Open = 'Open',
  Done = 'Done'
}
export enum IncentiveRecordsMonitoringRecordModelProcessStateEnum {
  None = 'None',
  Open = 'Open',
  TafCreated = 'TafCreated',
  TafPublished = 'TafPublished',
  ReadyForCalculation = 'ReadyForCalculation',
  Calculated = 'Calculated',
  Frozen = 'Frozen',
  AchievementLetterCreated = 'AchievementLetterCreated',
  AchievementLetterPublished = 'AchievementLetterPublished'
}
