/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommunicationsLetterPublicationsAddPublicationDateModel } from './communications-letter-publications-add-publication-date-model';

export interface SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel {
  letterIds: Array<string>;
  seasonId: string;
  seasonType?: SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum | null;
  workflowId?: string;
  managerType?: SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelManagerTypeEnum | null;
  employeeTaskTemplateId?: string | null;
  employeeMailTemplateId?: string | null;
  managerTaskTemplateId?: string | null;
  managerMailTemplateId?: string | null;
  hrBusinessPartnerMailTemplateId?: string | null;
  autoApprove?: boolean | null;
  overriddenPublicationDates?: Array<CommunicationsLetterPublicationsAddPublicationDateModel> | null;
}
export enum SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum {
  Unknown = 'Unknown',
  Sog = 'SOG',
  Gbr = 'GBR',
  Merit = 'Merit',
  IncentiveV2 = 'IncentiveV2',
  SpecialPaymentCash = 'SpecialPaymentCash',
  SpecialPaymentEquity = 'SpecialPaymentEquity',
  PositionEvaluation = 'PositionEvaluation',
  Restructuring = 'Restructuring'
}
export enum SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelManagerTypeEnum {
  LineManager = 'LineManager',
  CompanyManager = 'CompanyManager',
  None = 'None'
}
