import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import {
  CommunicationsPartnerService,
  HttpHelpersService,
  IncentivePartnerService,
  IncentivePartnerServiceQueryMonitoringRequestParams,
  LetterCreationService,
  LoadingService,
  TransactionCalculationOperations,
  TransactionService
} from '@coin/shared/data-access';
import { DownloadFileHelper, stateSnapshot } from '@coin/shared/util-helpers';
import {
  CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum,
  CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum,
  CommunicationsLetterPublicationsUnpublishLetterBatchModelLetterTypeEnum,
  IncentiveRecordsAssignmentLetterModel,
  IncentiveRecordsAssignmentLetterModelTypeEnum,
  IncentiveRecordsMonitoringRecordModel,
  IncentiveRecordsMonitoringRecordModelStateEnum,
  ListViewTagFilterParameter,
  PaginationState,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelLetterTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelSeasonTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum,
  TransactionStatusOfLetterBatchMetadata
} from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, tap } from 'rxjs';
import { MonitoringQuickFilter } from '../enums/monitoring-quick-filter.enum';
import { convertFiltersToBatchParams } from '../functions/convert-filters-to-batch-params';
import { MassOperationType } from '../models/mass-operation-type';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

interface MonitoringStateModel {
  monitoringList: IncentiveRecordsMonitoringRecordModel[];
  paginationState: PaginationState;
  filterParameter: ListViewTagFilterParameter[];
  isMultiselectActive: boolean;
  selectedItemIds: Set<string> | 'ALL';
}

const initialState = (): MonitoringStateModel => ({
  monitoringList: [],
  paginationState: {
    pageSize: 50,
    nextPage: 1,
    hasMore: true
  },
  filterParameter: [
    { category: MonitoringQuickFilter.State, value: IncentiveRecordsMonitoringRecordModelStateEnum.Active },
    { category: MonitoringQuickFilter.State, value: IncentiveRecordsMonitoringRecordModelStateEnum.Outdated }
  ],
  isMultiselectActive: false,
  selectedItemIds: new Set<string>()
});

@Injectable()
export class MonitoringComponentState extends ImmerComponentStore<MonitoringStateModel> {
  public transactionCalculationOperations = new TransactionCalculationOperations(this.transactionService, this.toastrService);

  public monitoringList$ = this.select(({ monitoringList }) => monitoringList);
  public recordsById$ = this.select(this.monitoringList$, monitoringList =>
    monitoringList.reduce<Record<string, IncentiveRecordsMonitoringRecordModel>>((accumulation, record) => {
      accumulation[record.id] = record;
      return accumulation;
    }, {})
  );
  public selectedItemIds$ = this.select(({ selectedItemIds }) => selectedItemIds);
  public totalCount$ = this.select(({ paginationState }) => paginationState.total || 0);
  public selectedItemsCount$ = this.select(this.selectedItemIds$, this.totalCount$, (selectedItemIds, totalCount) =>
    selectedItemIds === 'ALL' ? totalCount : selectedItemIds.size
  );
  public initialPageLoaded$ = this.select(({ paginationState }) => paginationState.nextPage >= initialState().paginationState.nextPage + 1);
  public filterParameter$ = this.select(({ filterParameter }) => filterParameter);
  public isMultiselectActive$ = this.select(({ isMultiselectActive }) => isMultiselectActive);
  public isFilterActive$ = this.select(
    ({ filterParameter }) =>
      filterParameter.map(({ value }) => value).toString() !==
      initialState()
        .filterParameter.map(({ value }) => value)
        .toString()
  );

  public isSelected(id: string): boolean {
    const { selectedItemIds } = this.get();
    return selectedItemIds === 'ALL' || selectedItemIds.has(id);
  }

  constructor(
    private incentiveSupportState: IncentiveSupportComponentState,
    private communicationsPartnerService: CommunicationsPartnerService,
    private store: Store,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private monitoringService: IncentivePartnerService,
    private destroyRef: DestroyRef,
    private letterCreationService: LetterCreationService,
    private transactionService: TransactionService,
    private toastrService: ToastrService
  ) {
    super(initialState());
    merge(this.incentiveSupportState.sortingChange$, this.incentiveSupportState.setFilter$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.toggleMultiselect(false);
        this.resetPaginationState();
        this.loadNextPage();
      });
  }

  public loadEmployeeData(id: string): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    if (selectedSeason) {
      // TODO: Load Employee Data
    }
  }

  public loadInitialPage(): void {
    this.toggleMultiselect(false);
    this.resetPaginationState();
    this.loadNextPage();
  }

  public loadNextPage(): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const { paginationState } = this.get();
    const { nextPage, pageSize, hasMore } = paginationState;

    if (hasMore) {
      const selectedSortOption = this.store.selectSnapshot(FilterSortState.selectedSortOption);

      const requestParams = {
        seasonId: selectedSeason.id,
        pagingPage: nextPage,
        pagingSize: pageSize,
        sortingOrderBy: selectedSortOption?.value as unknown as 'Asc' | 'Desc',
        sortingProperty: selectedSortOption?.key
      };

      const requestParamsWithFilters = this.addSelectedFilters(this.addSelectedQuickFilters(requestParams));

      this.monitoringService
        .queryMonitoring(requestParamsWithFilters)
        .pipe(
          this.loadingService.withLoadingScreen,
          this.httpHelpersService.withStatusMessages({ error: 'incentive-support.http-error-messages.loading-reassignments-failed' }),
          tap(response => {
            this.setState(state => {
              const { content, ...paginationState } = response;

              state.paginationState = paginationState;
              state.monitoringList = [...state.monitoringList, ...content];
            });
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  private addSelectedQuickFilters(requestParams: IncentivePartnerServiceQueryMonitoringRequestParams): IncentivePartnerServiceQueryMonitoringRequestParams {
    const { filterParameter } = this.get();
    const stateFilter = filterParameter.filter(parameter => parameter.category === MonitoringQuickFilter.State);

    return {
      ...requestParams,
      queryState: stateFilter as unknown as ('Active' | 'Draft' | 'Outdated' | 'Irrelevant')[]
    };
  }

  private addSelectedFilters(requestParams: IncentivePartnerServiceQueryMonitoringRequestParams): IncentivePartnerServiceQueryMonitoringRequestParams {
    const selectedFilters = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
    const returnParams = { ...requestParams };

    for (const selectedFilter of selectedFilters) {
      const isStartsWithOrContainsFilter = /StartsWith|Contains/.test(selectedFilter.key);
      const apiFilterKeyPrefix = 'query';
      const apiFilterKey = `${apiFilterKeyPrefix}${selectedFilter.key.replace(/StartsWith|Contains/, '')}`;

      if (selectedFilter.values) {
        returnParams[apiFilterKey] = isStartsWithOrContainsFilter ? selectedFilter.values[0] : selectedFilter.values;
      }
    }
    return returnParams;
  }

  public addFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = [...state.filterParameter, parameter];
    });
  }

  public removeFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = state.filterParameter.filter(existingFilterParameter => existingFilterParameter.value !== parameter.value);
    });
  }

  public resetFilterParameters(): void {
    this.setState(state => {
      state.filterParameter = initialState().filterParameter;
    });
  }

  public toggleMultiselect(isMultiselectActive: boolean): void {
    this.setState(state => {
      state.isMultiselectActive = isMultiselectActive;
    });
    this.unselectAll();
  }

  public selectAll(): void {
    this.toggleAll(true);
  }

  public unselectAll(): void {
    this.toggleAll(false);
  }

  private toggleAll(isSelected: boolean): void {
    this.setState(state => {
      if (isSelected) {
        state.selectedItemIds = 'ALL';
      } else {
        state.selectedItemIds = new Set();
      }
    });
  }

  public toggleSelection(id: string): void {
    this.setState(state => {
      if (state.selectedItemIds === 'ALL') {
        // select all currently visible items except for the toggled one
        state.selectedItemIds = new Set(state.monitoringList.map(item => item.id));
        state.selectedItemIds.delete(id);
        return;
      }

      if (state.selectedItemIds.has(id)) {
        state.selectedItemIds.delete(id);
      } else {
        state.selectedItemIds.add(id);
      }
    });
  }

  public resetPaginationState(): void {
    this.setState(state => {
      state.paginationState = initialState().paginationState;
      state.monitoringList = [];
    });
  }

  public downloadLetter(letter: IncentiveRecordsAssignmentLetterModel): void {
    this.letterCreationService
      .getLetterUserDocument(letter.name, letter.id)
      .pipe(this.loadingService.withLoadingScreen, takeUntilDestroyed(this.destroyRef))
      .subscribe(letterBase64 => DownloadFileHelper.download(letterBase64, letter.name));
  }

  getCurrentLetterByRecord(item: IncentiveRecordsMonitoringRecordModel) {
    if (item) {
      const letters = this.getLetterByType(item.letters || []);
      if (letters.length > 0) return letters[letters.length - 1];
    }

    return null;
  }

  private getLetterByType(letters: IncentiveRecordsAssignmentLetterModel[]) {
    return letters.filter(letter => letter.type === IncentiveRecordsAssignmentLetterModelTypeEnum.TargetAgreementForm);
  }

  public startListMassOperation(operationType: MassOperationType, recordIds: string[]): Observable<TransactionStatusOfLetterBatchMetadata> {
    const seasonId = this.incentiveSupportState.getSelectedSeason().id;
    const letterIds = this.getLetterIdsOfRecords(recordIds);

    switch (operationType) {
      case 'create':
        return this.communicationsPartnerService.createManyLetters({
          siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel: {
            seasonId,
            seasonType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelSeasonTypeEnum.IncentiveV2,
            recordIds,
            letterType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelLetterTypeEnum.TargetAgreementForm,
            // TODO: use select template dialog from Admin FE --> GMTEC-19162
            templateId: '4db9414b-aa9c-405b-9574-2869ba229660',
            numberFormatCountryIso: 'de-DE'
          }
        });
      case 'publish':
        return this.communicationsPartnerService.publishLetterListLetterPublications({
          siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel: {
            letterIds,
            seasonId,
            seasonType: SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum.IncentiveV2
          }
        });
      case 'unpublish':
        return this.communicationsPartnerService.listUnpublishLetterLetterPublications({
          communicationsLetterPublicationsUnpublishLetterListModel: { letterIds }
        });
      case 'forward': {
        return this.communicationsPartnerService.forwardLettersLetterActions({
          communicationsLetterActionsForwardLettersModel: { letterIds, seasonId }
        }); // TODO: might need to be mapped to a local "mock" transaction status
      }
      case 'auto-finalize':
        // TODO: use autofinalize dialog with date+message input from Admin FE --> GMTEC-19199
        return this.communicationsPartnerService.autoFinalizeLettersLetterFinalization({
          communicationsLetterFinalizationAutoFinalizeLettersModel: { letterIds, seasonId, date: '', message: '' }
        });
      case 'set-no-taf':
        throw new Error('not implemented');
      case 'set-irrelevant':
        throw new Error('not implemented');
    }
  }

  public startBatchMassOperation(operationType: MassOperationType): Observable<TransactionStatusOfLetterBatchMetadata> {
    const seasonId = this.incentiveSupportState.getSelectedSeason().id;
    const selectedFilterOptions = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
    const filtersAsBatchParams = convertFiltersToBatchParams(selectedFilterOptions);

    switch (operationType) {
      case 'create':
        return this.communicationsPartnerService.createLetterBatchLetters({
          siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel: {
            seasonId,
            seasonType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum.IncentiveV2,
            letterType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum.TargetAgreementForm,
            // TODO: use select template dialog from Admin FE --> GMTEC-19162
            templateId: '4db9414b-aa9c-405b-9574-2869ba229660',
            numberFormatCountryIso: 'de-DE'
          },
          ...filtersAsBatchParams
        });
      case 'publish':
        return this.communicationsPartnerService.publishLetterBatchLetterPublications({
          communicationsLetterPublicationsPublishLetterBatchModel: {
            seasonId,
            seasonType: CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum.IncentiveV2,
            letterType: CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum.TargetAgreementForm
          },
          ...filtersAsBatchParams
        });
      case 'unpublish':
        return this.communicationsPartnerService.batchUnpublishLetterLetterPublications({
          communicationsLetterPublicationsUnpublishLetterBatchModel: {
            seasonId,
            letterType: CommunicationsLetterPublicationsUnpublishLetterBatchModelLetterTypeEnum.TargetAgreementForm
          },
          ...filtersAsBatchParams
        });
      case 'forward':
        // TODO: clarify letter ids (Admin App does not have batch forward)
        return this.communicationsPartnerService.forwardLettersByQueryLetterActions({
          communicationsLetterActionsForwardLettersModel: { seasonId, letterIds: [] },
          ...filtersAsBatchParams
        });
      case 'auto-finalize':
        // TODO: use autofinalize dialog with date+message input from Admin FE --> GMTEC-19199
        return this.communicationsPartnerService.autoFinalizeLettersByQueryLetterFinalization({
          communicationsLetterFinalizationAutoFinalizeLettersByQueryModel: { seasonId, message: '', date: '' },
          ...filtersAsBatchParams
        });
      case 'set-no-taf':
        throw new Error('not implemented');
      case 'set-irrelevant':
        throw new Error('not implemented');
    }
  }

  private getLetterIdsOfRecords(recordIds: string[]): string[] {
    const recordsById = stateSnapshot(this.recordsById$);

    const letterIds = recordIds.map(recordId => this.getCurrentLetterByRecord(recordsById[recordId])?.id).filter(Boolean);
    return [...new Set(letterIds)];
  }
}
