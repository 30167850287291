/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommunicationsLetterPublicationsAddPublicationDateModel } from './communications-letter-publications-add-publication-date-model';

export interface CommunicationsLetterPublicationsPublishLetterBatchModel {
  seasonId: string;
  seasonType?: CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum | null;
  letterType: CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum;
  workflowId?: string;
  managerType?: CommunicationsLetterPublicationsPublishLetterBatchModelManagerTypeEnum | null;
  employeeTaskTemplateId?: string | null;
  employeeMailTemplateId?: string | null;
  managerTaskTemplateId?: string | null;
  managerMailTemplateId?: string | null;
  hrBusinessPartnerMailTemplateId?: string | null;
  overridenPublicationDates?: Array<CommunicationsLetterPublicationsAddPublicationDateModel> | null;
}
export enum CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum {
  Unknown = 'Unknown',
  Sog = 'SOG',
  Gbr = 'GBR',
  Merit = 'Merit',
  IncentiveV2 = 'IncentiveV2',
  SpecialPaymentCash = 'SpecialPaymentCash',
  SpecialPaymentEquity = 'SpecialPaymentEquity',
  PositionEvaluation = 'PositionEvaluation',
  Restructuring = 'Restructuring'
}
export enum CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum {
  Other = 'Other',
  AdherenceLetter = 'AdherenceLetter',
  TargetAgreementForm = 'TargetAgreementForm',
  TargetAchievementForm = 'TargetAchievementForm',
  MeritAllocation = 'MeritAllocation',
  SpecialPaymentLetter = 'SpecialPaymentLetter',
  RestructuringCalculation = 'RestructuringCalculation',
  RestructuringLetter = 'RestructuringLetter'
}
export enum CommunicationsLetterPublicationsPublishLetterBatchModelManagerTypeEnum {
  LineManager = 'LineManager',
  CompanyManager = 'CompanyManager',
  None = 'None'
}
