/**
 * RestructuringCustomerAPI
 * API for Restructuring customer module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: tim.weiss@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestructuringUpdateRestructuringRequestModel { 
    id?: string;
    planId?: string | null;
    state?: RestructuringUpdateRestructuringRequestModel.StateEnum | null;
    isFrozen?: boolean;
    reason?: string | null;
}
export namespace RestructuringUpdateRestructuringRequestModel {
    export type StateEnum = 'Draft' | 'Monitoring' | 'Accepted' | 'Rejected';
    export const StateEnum = {
        Draft: 'Draft' as StateEnum,
        Monitoring: 'Monitoring' as StateEnum,
        Accepted: 'Accepted' as StateEnum,
        Rejected: 'Rejected' as StateEnum
    };
}


