@if (item) {
  <coin-action-button class="{{ item.style }}" [icon]="item.icon" (click)="openPage(item['link'])">{{ item.text }}</coin-action-button>

  <div style="display: flex; flex-direction: column; width: 100%">
    @if (contentEdit) {
      <coin-v2-text-input [label]="'cms.button-text' | translate" [(ngModel)]="item['text']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" />
    }
    @if (contentEdit) {
      <coin-v2-text-input [label]="'cms.button-link' | translate" [(ngModel)]="item['link']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" />
    }

    @if (contentEdit) {
      <mat-form-field appearance="fill">
        <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
        <mat-select [(ngModel)]="item.style">
          @for (option of buttonStyles; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    @if (contentEdit) {
      <mat-form-field appearance="fill">
        <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
        <mat-select [(ngModel)]="item.icon">
          @for (option of buttonIcons; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </div>
}
