import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class LetterCreationService {
  private httpClient = inject(HttpClient);
  private toast = inject(ToastrService);

  getLetterUserDocument(letterName: string, letterId?: string): Observable<string> {
    return this.httpClient
      .get(`${environment.api.letterServiceUrl}/letters/${encodeURIComponent(letterName)}`, {
        responseType: 'text',
        params: {
          letterId
        }
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.toast.error(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
          return throwError(`Error getting letter. ${res?.error?.reason || 'Unknown'} `);
        })
      );
  }
}
