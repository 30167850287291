/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  IncentiveRecordsAssignmentRecordPartnerModel,
  IncentiveRecordsCalculateManyProrationsModel,
  IncentiveRecordsIncentiveRecordPartnerModel,
  IncentiveRecordsRecordBatchPartnerResponseModel,
  IncentiveRecordsUpdateManyRecordStatesPartnerModel,
  IncentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel,
  IncentiveRecordsUpdateRecordPartnerModel,
  SharedPageModelOfIncentiveRecordsAssignmentRecordPartnerModel,
  SharedPageModelOfIncentiveRecordsMonitoringRecordModel,
  SharedPageModelOfSystemString,
  TransactionStatusOfIncentiveProrationCalculationMetadata
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface IncentivePartnerServiceCalculateManyProrationsRecordCalculationsRequestParams {
  seasonId: string;
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  planName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  startDate?: string;
  endDate?: string;
  payoutDate?: string;
  assignmentManager?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId2?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  measurementDate?: string;
  incentiveRecordsCalculateManyProrationsModel?: IncentiveRecordsCalculateManyProrationsModel;
}

export interface IncentivePartnerServiceCalculateProrationRecordCalculationsRequestParams {
  seasonId: string;
  recordId: string;
}

export interface IncentivePartnerServiceGetRecordsRequestParams {
  id: string;
  seasonId: string;
}

export interface IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams {
  seasonId: string;
  targetProperty?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
  assignmentId?: string;
  gid?: string;
  firstname?: string;
  lastname?: string;
  are?: string;
  country?: string;
  orgCode?: string;
  planName?: string;
  letterState?: string;
  recordCommunicationStates?: string;
  processStates?: string;
  template?: string;
  workflow?: string;
  payoutState?: string;
  exportState?: string;
  incentivePaymentType?: string;
  page?: number;
  size?: number;
}

export interface IncentivePartnerServiceQueryFieldValuesRecordsRequestParams {
  seasonId: string;
  targetProperty?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
  assignmentId?: string;
  gid?: string;
  firstname?: string;
  lastname?: string;
  are?: string;
  country?: string;
  orgCode?: string;
  planName?: string;
  letterState?: string;
  recordCommunicationStates?: string;
  processStates?: string;
  template?: string;
  workflow?: string;
  payoutState?: string;
  exportState?: string;
  incentivePaymentType?: string;
  page?: number;
  size?: number;
}

export interface IncentivePartnerServiceQueryMonitoringRequestParams {
  seasonId: string;
  employeeId?: string;
  recordState?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  queryId?: string;
  queryGid?: Array<string>;
  queryFirstName?: Array<string>;
  queryLastName?: Array<string>;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanName?: Array<string>;
  queryState?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryPayoutDate?: string;
  queryAssignmentManager?: string;
  queryAssignmentId?: string;
  queryLetterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  queryProcessStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  queryRecordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  queryWorkflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  queryTemplate?: Array<string>;
  queryIsDesignReady?: boolean;
  queryPayoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  queryManagerSetMercerJobCode?: Array<string>;
  queryEditedMercerJobCode?: Array<string>;
  queryCurrentMercerJobCode?: Array<string>;
  queryIsMercerJobCodeValid?: boolean;
  queryIsGripCodeValid?: boolean;
  queryLineManagerName?: string;
  queryLineManagerGid?: Array<string>;
  querySeasonId?: string;
  queryWorkflowInstanceState?: Array<
    'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'
  >;
  queryMultiplier?: number;
  queryOverallTargetAchievement?: number;
  queryPayoutAmount?: number;
  queryPayoutCurrency?: Array<string>;
  queryIppAchievementValue?: string;
  queryIncentivePaymentType?: string;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface IncentivePartnerServiceQueryRecordsRequestParams {
  seasonId: string;
  employeeId?: string;
  recordState?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  queryId?: string;
  queryGid?: Array<string>;
  queryFirstName?: Array<string>;
  queryLastName?: Array<string>;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanName?: Array<string>;
  queryState?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryPayoutDate?: string;
  queryAssignmentManager?: string;
  queryAssignmentId?: string;
  queryLetterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  queryProcessStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  queryRecordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  queryWorkflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  queryTemplate?: Array<string>;
  queryIsDesignReady?: boolean;
  queryPayoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  queryLineManagerName?: string;
  queryLineManagerGid?: Array<string>;
  querySeasonId?: string;
  queryWorkflowInstanceState?: Array<
    'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'
  >;
  queryMultiplier?: number;
  queryOverallTargetAchievement?: number;
  queryPayoutAmount?: number;
  queryPayoutCurrency?: Array<string>;
  queryIppAchievementValue?: string;
  queryIncentivePaymentType?: Array<string>;
  queryMeritTitle?: Array<string>;
  queryManuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  queryNewHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  queryPromotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  queryTermination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  queryPopulationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  queryLineManagerFirstName?: Array<string>;
  queryLineManagerLastName?: Array<string>;
  queryCompanyManagerFirstName?: Array<string>;
  queryCompanyManagerGid?: Array<string>;
  queryCompanyManagerLastName?: Array<string>;
  queryTemplateName?: Array<string>;
  queryWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  queryAutoApprove?: boolean;
  queryApprovalRequired?: boolean;
  queryLetterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  queryProcessState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  queryExportState?: Array<'Open' | 'Done'>;
  queryPayrollState?: Array<'Open' | 'Done'>;
  queryMeritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  queryApprovedByCompanyManager?: boolean;
  queryAllocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  queryReadyToPayroll?: boolean;
  queryPlanTitle?: Array<string>;
  queryLocalCurrency?: Array<string>;
  queryProposalAmount?: number;
  queryProposalDate?: string;
  querySpecialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  queryTemplateLetterName?: Array<string>;
  queryMeasurementDate?: string;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface IncentivePartnerServiceUpdateRecordIndividualMultiplierValueRecordsRequestParams {
  seasonId: string;
  id: string;
  incentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel?: IncentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel;
}

export interface IncentivePartnerServiceUpdateRecordStatesRecordsRequestParams {
  seasonId: string;
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  planName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  startDate?: string;
  endDate?: string;
  payoutDate?: string;
  assignmentManager?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId2?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  measurementDate?: string;
  incentiveRecordsUpdateManyRecordStatesPartnerModel?: IncentiveRecordsUpdateManyRecordStatesPartnerModel;
}

export interface IncentivePartnerServiceUpdateRecordsRequestParams {
  seasonId: string;
  id: string;
  incentiveRecordsUpdateRecordPartnerModel?: IncentiveRecordsUpdateRecordPartnerModel;
}

@Injectable({
  providedIn: 'root'
})
export class IncentivePartnerService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public calculateManyProrationsRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateManyProrationsRecordCalculationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfIncentiveProrationCalculationMetadata>;
  public calculateManyProrationsRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateManyProrationsRecordCalculationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfIncentiveProrationCalculationMetadata>>;
  public calculateManyProrationsRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateManyProrationsRecordCalculationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfIncentiveProrationCalculationMetadata>>;
  public calculateManyProrationsRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateManyProrationsRecordCalculationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling calculateManyProrationsRecordCalculations.');
    }
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const planName = requestParameters?.planName;
    const state = requestParameters?.state;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const payoutDate = requestParameters?.payoutDate;
    const assignmentManager = requestParameters?.assignmentManager;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId2 = requestParameters?.seasonId2;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const measurementDate = requestParameters?.measurementDate;
    const incentiveRecordsCalculateManyProrationsModel = requestParameters?.incentiveRecordsCalculateManyProrationsModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (assignmentManager !== undefined && assignmentManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentManager, 'AssignmentManager');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId2 !== undefined && seasonId2 !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId2, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/proration`;
    return this.httpClient.request<TransactionStatusOfIncentiveProrationCalculationMetadata>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: incentiveRecordsCalculateManyProrationsModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public calculateProrationRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateProrationRecordCalculationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfIncentiveProrationCalculationMetadata>;
  public calculateProrationRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateProrationRecordCalculationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfIncentiveProrationCalculationMetadata>>;
  public calculateProrationRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateProrationRecordCalculationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfIncentiveProrationCalculationMetadata>>;
  public calculateProrationRecordCalculations(
    requestParameters?: IncentivePartnerServiceCalculateProrationRecordCalculationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling calculateProrationRecordCalculations.');
    }
    const recordId = requestParameters?.recordId;
    if (recordId === null || recordId === undefined) {
      throw new Error('Required parameter recordId was null or undefined when calling calculateProrationRecordCalculations.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/${this.configuration.encodeParam({ name: 'recordId', value: recordId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/proration`;
    return this.httpClient.request<TransactionStatusOfIncentiveProrationCalculationMetadata>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecords(
    requestParameters?: IncentivePartnerServiceGetRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<IncentiveRecordsAssignmentRecordPartnerModel>;
  public getRecords(
    requestParameters?: IncentivePartnerServiceGetRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<IncentiveRecordsAssignmentRecordPartnerModel>>;
  public getRecords(
    requestParameters?: IncentivePartnerServiceGetRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<IncentiveRecordsAssignmentRecordPartnerModel>>;
  public getRecords(
    requestParameters?: IncentivePartnerServiceGetRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRecords.');
    }
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getRecords.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/records/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<IncentiveRecordsAssignmentRecordPartnerModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValuesMonitoring(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public queryFieldValuesMonitoring(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public queryFieldValuesMonitoring(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public queryFieldValuesMonitoring(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling queryFieldValuesMonitoring.');
    }
    const targetProperty = requestParameters?.targetProperty;
    const assignmentId = requestParameters?.assignmentId;
    const gid = requestParameters?.gid;
    const firstname = requestParameters?.firstname;
    const lastname = requestParameters?.lastname;
    const are = requestParameters?.are;
    const country = requestParameters?.country;
    const orgCode = requestParameters?.orgCode;
    const planName = requestParameters?.planName;
    const letterState = requestParameters?.letterState;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const processStates = requestParameters?.processStates;
    const template = requestParameters?.template;
    const workflow = requestParameters?.workflow;
    const payoutState = requestParameters?.payoutState;
    const exportState = requestParameters?.exportState;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (targetProperty !== undefined && targetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetProperty, 'TargetProperty');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (gid !== undefined && gid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>gid, 'Gid');
    }
    if (firstname !== undefined && firstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>firstname, 'Firstname');
    }
    if (lastname !== undefined && lastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lastname, 'Lastname');
    }
    if (are !== undefined && are !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>are, 'Are');
    }
    if (country !== undefined && country !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>country, 'Country');
    }
    if (orgCode !== undefined && orgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCode, 'OrgCode');
    }
    if (planName !== undefined && planName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>planName, 'PlanName');
    }
    if (letterState !== undefined && letterState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterState, 'LetterState');
    }
    if (recordCommunicationStates !== undefined && recordCommunicationStates !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>recordCommunicationStates, 'RecordCommunicationStates');
    }
    if (processStates !== undefined && processStates !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>processStates, 'ProcessStates');
    }
    if (template !== undefined && template !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>template, 'Template');
    }
    if (workflow !== undefined && workflow !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>workflow, 'Workflow');
    }
    if (payoutState !== undefined && payoutState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutState, 'PayoutState');
    }
    if (exportState !== undefined && exportState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>exportState, 'ExportState');
    }
    if (incentivePaymentType !== undefined && incentivePaymentType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>incentivePaymentType, 'IncentivePaymentType');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/partner/v1/monitoring/field-values`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValuesRecords(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public queryFieldValuesRecords(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public queryFieldValuesRecords(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public queryFieldValuesRecords(
    requestParameters?: IncentivePartnerServiceQueryFieldValuesRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling queryFieldValuesRecords.');
    }
    const targetProperty = requestParameters?.targetProperty;
    const assignmentId = requestParameters?.assignmentId;
    const gid = requestParameters?.gid;
    const firstname = requestParameters?.firstname;
    const lastname = requestParameters?.lastname;
    const are = requestParameters?.are;
    const country = requestParameters?.country;
    const orgCode = requestParameters?.orgCode;
    const planName = requestParameters?.planName;
    const letterState = requestParameters?.letterState;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const processStates = requestParameters?.processStates;
    const template = requestParameters?.template;
    const workflow = requestParameters?.workflow;
    const payoutState = requestParameters?.payoutState;
    const exportState = requestParameters?.exportState;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (targetProperty !== undefined && targetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetProperty, 'TargetProperty');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (gid !== undefined && gid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>gid, 'Gid');
    }
    if (firstname !== undefined && firstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>firstname, 'Firstname');
    }
    if (lastname !== undefined && lastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lastname, 'Lastname');
    }
    if (are !== undefined && are !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>are, 'Are');
    }
    if (country !== undefined && country !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>country, 'Country');
    }
    if (orgCode !== undefined && orgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCode, 'OrgCode');
    }
    if (planName !== undefined && planName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>planName, 'PlanName');
    }
    if (letterState !== undefined && letterState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterState, 'LetterState');
    }
    if (recordCommunicationStates !== undefined && recordCommunicationStates !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>recordCommunicationStates, 'RecordCommunicationStates');
    }
    if (processStates !== undefined && processStates !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>processStates, 'ProcessStates');
    }
    if (template !== undefined && template !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>template, 'Template');
    }
    if (workflow !== undefined && workflow !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>workflow, 'Workflow');
    }
    if (payoutState !== undefined && payoutState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutState, 'PayoutState');
    }
    if (exportState !== undefined && exportState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>exportState, 'ExportState');
    }
    if (incentivePaymentType !== undefined && incentivePaymentType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>incentivePaymentType, 'IncentivePaymentType');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/field-values`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryMonitoring(
    requestParameters?: IncentivePartnerServiceQueryMonitoringRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfIncentiveRecordsMonitoringRecordModel>;
  public queryMonitoring(
    requestParameters?: IncentivePartnerServiceQueryMonitoringRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfIncentiveRecordsMonitoringRecordModel>>;
  public queryMonitoring(
    requestParameters?: IncentivePartnerServiceQueryMonitoringRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfIncentiveRecordsMonitoringRecordModel>>;
  public queryMonitoring(
    requestParameters?: IncentivePartnerServiceQueryMonitoringRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling queryMonitoring.');
    }
    const employeeId = requestParameters?.employeeId;
    const recordState = requestParameters?.recordState;
    const queryId = requestParameters?.queryId;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanName = requestParameters?.queryPlanName;
    const queryState = requestParameters?.queryState;
    const queryStartDate = requestParameters?.queryStartDate;
    const queryEndDate = requestParameters?.queryEndDate;
    const queryPayoutDate = requestParameters?.queryPayoutDate;
    const queryAssignmentManager = requestParameters?.queryAssignmentManager;
    const queryAssignmentId = requestParameters?.queryAssignmentId;
    const queryLetterType = requestParameters?.queryLetterType;
    const queryProcessStates = requestParameters?.queryProcessStates;
    const queryRecordCommunicationStates = requestParameters?.queryRecordCommunicationStates;
    const queryWorkflow = requestParameters?.queryWorkflow;
    const queryTemplate = requestParameters?.queryTemplate;
    const queryIsDesignReady = requestParameters?.queryIsDesignReady;
    const queryPayoutState = requestParameters?.queryPayoutState;
    const queryManagerSetMercerJobCode = requestParameters?.queryManagerSetMercerJobCode;
    const queryEditedMercerJobCode = requestParameters?.queryEditedMercerJobCode;
    const queryCurrentMercerJobCode = requestParameters?.queryCurrentMercerJobCode;
    const queryIsMercerJobCodeValid = requestParameters?.queryIsMercerJobCodeValid;
    const queryIsGripCodeValid = requestParameters?.queryIsGripCodeValid;
    const queryLineManagerName = requestParameters?.queryLineManagerName;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryWorkflowInstanceState = requestParameters?.queryWorkflowInstanceState;
    const queryMultiplier = requestParameters?.queryMultiplier;
    const queryOverallTargetAchievement = requestParameters?.queryOverallTargetAchievement;
    const queryPayoutAmount = requestParameters?.queryPayoutAmount;
    const queryPayoutCurrency = requestParameters?.queryPayoutCurrency;
    const queryIppAchievementValue = requestParameters?.queryIppAchievementValue;
    const queryIncentivePaymentType = requestParameters?.queryIncentivePaymentType;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (employeeId !== undefined && employeeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>employeeId, 'employeeId');
    }
    if (recordState) {
      recordState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'recordState');
      });
    }
    if (queryId !== undefined && queryId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryId, 'Query.Id');
    }
    if (queryGid) {
      queryGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Gid');
      });
    }
    if (queryFirstName) {
      queryFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FirstName');
      });
    }
    if (queryLastName) {
      queryLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LastName');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanName) {
      queryPlanName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanName');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'Query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'Query.EndDate');
    }
    if (queryPayoutDate !== undefined && queryPayoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryPayoutDate, 'Query.PayoutDate');
    }
    if (queryAssignmentManager !== undefined && queryAssignmentManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignmentManager, 'Query.AssignmentManager');
    }
    if (queryAssignmentId !== undefined && queryAssignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignmentId, 'Query.AssignmentId');
    }
    if (queryLetterType !== undefined && queryLetterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLetterType, 'Query.LetterType');
    }
    if (queryProcessStates) {
      queryProcessStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ProcessStates');
      });
    }
    if (queryRecordCommunicationStates) {
      queryRecordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RecordCommunicationStates');
      });
    }
    if (queryWorkflow) {
      queryWorkflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Workflow');
      });
    }
    if (queryTemplate) {
      queryTemplate.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Template');
      });
    }
    if (queryIsDesignReady !== undefined && queryIsDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsDesignReady, 'Query.IsDesignReady');
    }
    if (queryPayoutState) {
      queryPayoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PayoutState');
      });
    }
    if (queryManagerSetMercerJobCode) {
      queryManagerSetMercerJobCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ManagerSetMercerJobCode');
      });
    }
    if (queryEditedMercerJobCode) {
      queryEditedMercerJobCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.EditedMercerJobCode');
      });
    }
    if (queryCurrentMercerJobCode) {
      queryCurrentMercerJobCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CurrentMercerJobCode');
      });
    }
    if (queryIsMercerJobCodeValid !== undefined && queryIsMercerJobCodeValid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsMercerJobCodeValid, 'Query.IsMercerJobCodeValid');
    }
    if (queryIsGripCodeValid !== undefined && queryIsGripCodeValid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsGripCodeValid, 'Query.IsGripCodeValid');
    }
    if (queryLineManagerName !== undefined && queryLineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLineManagerName, 'Query.LineManagerName');
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (querySeasonId !== undefined && querySeasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>querySeasonId, 'Query.SeasonId');
    }
    if (queryWorkflowInstanceState) {
      queryWorkflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.WorkflowInstanceState');
      });
    }
    if (queryMultiplier !== undefined && queryMultiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryMultiplier, 'Query.Multiplier');
    }
    if (queryOverallTargetAchievement !== undefined && queryOverallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOverallTargetAchievement, 'Query.OverallTargetAchievement');
    }
    if (queryPayoutAmount !== undefined && queryPayoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryPayoutAmount, 'Query.PayoutAmount');
    }
    if (queryPayoutCurrency) {
      queryPayoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PayoutCurrency');
      });
    }
    if (queryIppAchievementValue !== undefined && queryIppAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIppAchievementValue, 'Query.IppAchievementValue');
    }
    if (queryIncentivePaymentType !== undefined && queryIncentivePaymentType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIncentivePaymentType, 'Query.IncentivePaymentType');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/partner/v1/monitoring`;
    return this.httpClient.request<SharedPageModelOfIncentiveRecordsMonitoringRecordModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryRecords(
    requestParameters?: IncentivePartnerServiceQueryRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfIncentiveRecordsAssignmentRecordPartnerModel>;
  public queryRecords(
    requestParameters?: IncentivePartnerServiceQueryRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfIncentiveRecordsAssignmentRecordPartnerModel>>;
  public queryRecords(
    requestParameters?: IncentivePartnerServiceQueryRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfIncentiveRecordsAssignmentRecordPartnerModel>>;
  public queryRecords(
    requestParameters?: IncentivePartnerServiceQueryRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling queryRecords.');
    }
    const employeeId = requestParameters?.employeeId;
    const recordState = requestParameters?.recordState;
    const queryId = requestParameters?.queryId;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanName = requestParameters?.queryPlanName;
    const queryState = requestParameters?.queryState;
    const queryStartDate = requestParameters?.queryStartDate;
    const queryEndDate = requestParameters?.queryEndDate;
    const queryPayoutDate = requestParameters?.queryPayoutDate;
    const queryAssignmentManager = requestParameters?.queryAssignmentManager;
    const queryAssignmentId = requestParameters?.queryAssignmentId;
    const queryLetterType = requestParameters?.queryLetterType;
    const queryProcessStates = requestParameters?.queryProcessStates;
    const queryRecordCommunicationStates = requestParameters?.queryRecordCommunicationStates;
    const queryWorkflow = requestParameters?.queryWorkflow;
    const queryTemplate = requestParameters?.queryTemplate;
    const queryIsDesignReady = requestParameters?.queryIsDesignReady;
    const queryPayoutState = requestParameters?.queryPayoutState;
    const queryLineManagerName = requestParameters?.queryLineManagerName;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryWorkflowInstanceState = requestParameters?.queryWorkflowInstanceState;
    const queryMultiplier = requestParameters?.queryMultiplier;
    const queryOverallTargetAchievement = requestParameters?.queryOverallTargetAchievement;
    const queryPayoutAmount = requestParameters?.queryPayoutAmount;
    const queryPayoutCurrency = requestParameters?.queryPayoutCurrency;
    const queryIppAchievementValue = requestParameters?.queryIppAchievementValue;
    const queryIncentivePaymentType = requestParameters?.queryIncentivePaymentType;
    const queryMeritTitle = requestParameters?.queryMeritTitle;
    const queryManuallyChanged = requestParameters?.queryManuallyChanged;
    const queryNewHire = requestParameters?.queryNewHire;
    const queryPromotion = requestParameters?.queryPromotion;
    const queryTermination = requestParameters?.queryTermination;
    const queryPopulationChange = requestParameters?.queryPopulationChange;
    const queryLineManagerFirstName = requestParameters?.queryLineManagerFirstName;
    const queryLineManagerLastName = requestParameters?.queryLineManagerLastName;
    const queryCompanyManagerFirstName = requestParameters?.queryCompanyManagerFirstName;
    const queryCompanyManagerGid = requestParameters?.queryCompanyManagerGid;
    const queryCompanyManagerLastName = requestParameters?.queryCompanyManagerLastName;
    const queryTemplateName = requestParameters?.queryTemplateName;
    const queryWorkflowType = requestParameters?.queryWorkflowType;
    const queryAutoApprove = requestParameters?.queryAutoApprove;
    const queryApprovalRequired = requestParameters?.queryApprovalRequired;
    const queryLetterState = requestParameters?.queryLetterState;
    const queryProcessState = requestParameters?.queryProcessState;
    const queryExportState = requestParameters?.queryExportState;
    const queryPayrollState = requestParameters?.queryPayrollState;
    const queryMeritRelevancy = requestParameters?.queryMeritRelevancy;
    const queryApprovedByCompanyManager = requestParameters?.queryApprovedByCompanyManager;
    const queryAllocationWorkflowType = requestParameters?.queryAllocationWorkflowType;
    const queryReadyToPayroll = requestParameters?.queryReadyToPayroll;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const queryLocalCurrency = requestParameters?.queryLocalCurrency;
    const queryProposalAmount = requestParameters?.queryProposalAmount;
    const queryProposalDate = requestParameters?.queryProposalDate;
    const querySpecialPaymentReason = requestParameters?.querySpecialPaymentReason;
    const queryTemplateLetterName = requestParameters?.queryTemplateLetterName;
    const queryMeasurementDate = requestParameters?.queryMeasurementDate;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (employeeId !== undefined && employeeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>employeeId, 'employeeId');
    }
    if (recordState) {
      recordState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'recordState');
      });
    }
    if (queryId !== undefined && queryId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryId, 'Query.Id');
    }
    if (queryGid) {
      queryGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Gid');
      });
    }
    if (queryFirstName) {
      queryFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FirstName');
      });
    }
    if (queryLastName) {
      queryLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LastName');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanName) {
      queryPlanName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanName');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'Query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'Query.EndDate');
    }
    if (queryPayoutDate !== undefined && queryPayoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryPayoutDate, 'Query.PayoutDate');
    }
    if (queryAssignmentManager !== undefined && queryAssignmentManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignmentManager, 'Query.AssignmentManager');
    }
    if (queryAssignmentId !== undefined && queryAssignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignmentId, 'Query.AssignmentId');
    }
    if (queryLetterType !== undefined && queryLetterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLetterType, 'Query.LetterType');
    }
    if (queryProcessStates) {
      queryProcessStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ProcessStates');
      });
    }
    if (queryRecordCommunicationStates) {
      queryRecordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RecordCommunicationStates');
      });
    }
    if (queryWorkflow) {
      queryWorkflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Workflow');
      });
    }
    if (queryTemplate) {
      queryTemplate.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Template');
      });
    }
    if (queryIsDesignReady !== undefined && queryIsDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsDesignReady, 'Query.IsDesignReady');
    }
    if (queryPayoutState) {
      queryPayoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PayoutState');
      });
    }
    if (queryLineManagerName !== undefined && queryLineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLineManagerName, 'Query.LineManagerName');
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (querySeasonId !== undefined && querySeasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>querySeasonId, 'Query.SeasonId');
    }
    if (queryWorkflowInstanceState) {
      queryWorkflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.WorkflowInstanceState');
      });
    }
    if (queryMultiplier !== undefined && queryMultiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryMultiplier, 'Query.Multiplier');
    }
    if (queryOverallTargetAchievement !== undefined && queryOverallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOverallTargetAchievement, 'Query.OverallTargetAchievement');
    }
    if (queryPayoutAmount !== undefined && queryPayoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryPayoutAmount, 'Query.PayoutAmount');
    }
    if (queryPayoutCurrency) {
      queryPayoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PayoutCurrency');
      });
    }
    if (queryIppAchievementValue !== undefined && queryIppAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIppAchievementValue, 'Query.IppAchievementValue');
    }
    if (queryIncentivePaymentType) {
      queryIncentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.IncentivePaymentType');
      });
    }
    if (queryMeritTitle) {
      queryMeritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.MeritTitle');
      });
    }
    if (queryManuallyChanged) {
      queryManuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ManuallyChanged');
      });
    }
    if (queryNewHire) {
      queryNewHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.NewHire');
      });
    }
    if (queryPromotion) {
      queryPromotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Promotion');
      });
    }
    if (queryTermination) {
      queryTermination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Termination');
      });
    }
    if (queryPopulationChange) {
      queryPopulationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PopulationChange');
      });
    }
    if (queryLineManagerFirstName) {
      queryLineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerFirstName');
      });
    }
    if (queryLineManagerLastName) {
      queryLineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerLastName');
      });
    }
    if (queryCompanyManagerFirstName) {
      queryCompanyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerFirstName');
      });
    }
    if (queryCompanyManagerGid) {
      queryCompanyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerGid');
      });
    }
    if (queryCompanyManagerLastName) {
      queryCompanyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerLastName');
      });
    }
    if (queryTemplateName) {
      queryTemplateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.TemplateName');
      });
    }
    if (queryWorkflowType) {
      queryWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.WorkflowType');
      });
    }
    if (queryAutoApprove !== undefined && queryAutoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAutoApprove, 'Query.AutoApprove');
    }
    if (queryApprovalRequired !== undefined && queryApprovalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryApprovalRequired, 'Query.ApprovalRequired');
    }
    if (queryLetterState) {
      queryLetterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LetterState');
      });
    }
    if (queryProcessState) {
      queryProcessState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ProcessState');
      });
    }
    if (queryExportState) {
      queryExportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ExportState');
      });
    }
    if (queryPayrollState) {
      queryPayrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PayrollState');
      });
    }
    if (queryMeritRelevancy) {
      queryMeritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.MeritRelevancy');
      });
    }
    if (queryApprovedByCompanyManager !== undefined && queryApprovedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryApprovedByCompanyManager, 'Query.ApprovedByCompanyManager');
    }
    if (queryAllocationWorkflowType) {
      queryAllocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.AllocationWorkflowType');
      });
    }
    if (queryReadyToPayroll !== undefined && queryReadyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReadyToPayroll, 'Query.ReadyToPayroll');
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (queryLocalCurrency) {
      queryLocalCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LocalCurrency');
      });
    }
    if (queryProposalAmount !== undefined && queryProposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryProposalAmount, 'Query.ProposalAmount');
    }
    if (queryProposalDate !== undefined && queryProposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryProposalDate, 'Query.ProposalDate');
    }
    if (querySpecialPaymentReason) {
      querySpecialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SpecialPaymentReason');
      });
    }
    if (queryTemplateLetterName) {
      queryTemplateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.TemplateLetterName');
      });
    }
    if (queryMeasurementDate !== undefined && queryMeasurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryMeasurementDate, 'Query.MeasurementDate');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records`;
    return this.httpClient.request<SharedPageModelOfIncentiveRecordsAssignmentRecordPartnerModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRecordIndividualMultiplierValueRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordIndividualMultiplierValueRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<IncentiveRecordsIncentiveRecordPartnerModel>;
  public updateRecordIndividualMultiplierValueRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordIndividualMultiplierValueRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<IncentiveRecordsIncentiveRecordPartnerModel>>;
  public updateRecordIndividualMultiplierValueRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordIndividualMultiplierValueRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<IncentiveRecordsIncentiveRecordPartnerModel>>;
  public updateRecordIndividualMultiplierValueRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordIndividualMultiplierValueRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling updateRecordIndividualMultiplierValueRecords.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRecordIndividualMultiplierValueRecords.');
    }
    const incentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel = requestParameters?.incentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/individual-multiplier-value`;
    return this.httpClient.request<IncentiveRecordsIncentiveRecordPartnerModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: incentiveRecordsUpdateRecordIndividualMultiplierValuePartnerModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRecordStatesRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordStatesRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<IncentiveRecordsRecordBatchPartnerResponseModel>;
  public updateRecordStatesRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordStatesRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<IncentiveRecordsRecordBatchPartnerResponseModel>>;
  public updateRecordStatesRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordStatesRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<IncentiveRecordsRecordBatchPartnerResponseModel>>;
  public updateRecordStatesRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordStatesRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling updateRecordStatesRecords.');
    }
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const planName = requestParameters?.planName;
    const state = requestParameters?.state;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const payoutDate = requestParameters?.payoutDate;
    const assignmentManager = requestParameters?.assignmentManager;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId2 = requestParameters?.seasonId2;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const measurementDate = requestParameters?.measurementDate;
    const incentiveRecordsUpdateManyRecordStatesPartnerModel = requestParameters?.incentiveRecordsUpdateManyRecordStatesPartnerModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (assignmentManager !== undefined && assignmentManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentManager, 'AssignmentManager');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId2 !== undefined && seasonId2 !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId2, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/states`;
    return this.httpClient.request<IncentiveRecordsRecordBatchPartnerResponseModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: incentiveRecordsUpdateManyRecordStatesPartnerModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<IncentiveRecordsIncentiveRecordPartnerModel>;
  public updateRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<IncentiveRecordsIncentiveRecordPartnerModel>>;
  public updateRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<IncentiveRecordsIncentiveRecordPartnerModel>>;
  public updateRecords(
    requestParameters?: IncentivePartnerServiceUpdateRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling updateRecords.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRecords.');
    }
    const incentiveRecordsUpdateRecordPartnerModel = requestParameters?.incentiveRecordsUpdateRecordPartnerModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/records/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<IncentiveRecordsIncentiveRecordPartnerModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: incentiveRecordsUpdateRecordPartnerModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
