/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersUsersUserModel } from './users-users-user-model';

export interface IncentiveRecordsAssignmentLetterModel {
  /**
   * Should always map to the OriginId of a Letter!
   */
  id?: string;
  name?: string | null;
  templateName?: string | null;
  workflowType?: IncentiveRecordsAssignmentLetterModelWorkflowTypeEnum | null;
  letterState?: IncentiveRecordsAssignmentLetterModelLetterStateEnum;
  type?: IncentiveRecordsAssignmentLetterModelTypeEnum;
  createdBy?: UsersUsersUserModel;
}
export enum IncentiveRecordsAssignmentLetterModelWorkflowTypeEnum {
  EmployeeConfirmation = 'EmployeeConfirmation',
  EmployeeSignature = 'EmployeeSignature',
  ManagerConfirmationEmployeeConfirmation = 'ManagerConfirmationEmployeeConfirmation',
  ManagerConfirmationEmployeeSignature = 'ManagerConfirmationEmployeeSignature',
  ManagerSignatureEmployeeConfirmation = 'ManagerSignatureEmployeeConfirmation',
  ManagerSignatureEmployeeSignature = 'ManagerSignatureEmployeeSignature',
  ManagerConfirmationEmployeeNoActionNeeded = 'ManagerConfirmationEmployeeNoActionNeeded',
  NoActionNeeded = 'NoActionNeeded',
  AllocationByInCompanyManagerInvisibleForLineManager = 'AllocationByInCompanyManagerInvisibleForLineManager',
  AllocationByInCompanyManagerVisibleForLineManager = 'AllocationByInCompanyManagerVisibleForLineManager',
  AllocationByLineManagerApprovalByInCompanyManager = 'AllocationByLineManagerApprovalByInCompanyManager',
  AllocationByLineManagerVisibleForInCompanyManager = 'AllocationByLineManagerVisibleForInCompanyManager',
  BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation = 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation',
  BusinessPartnerConfirmationManagerConfirmationEmployeeSignature = 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature',
  BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation = 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation',
  BusinessPartnerConfirmationManagerSignatureEmployeeSignature = 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature',
  BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded = 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded',
  BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded = 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded',
  BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation = 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation',
  BusinessPartnerSignatureManagerConfirmationEmployeeSignature = 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature',
  BusinessPartnerSignatureManagerSignatureEmployeeConfirmation = 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation',
  BusinessPartnerSignatureManagerSignatureEmployeeSignature = 'BusinessPartnerSignatureManagerSignatureEmployeeSignature',
  BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded = 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded',
  BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded = 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
}
export enum IncentiveRecordsAssignmentLetterModelLetterStateEnum {
  None = 'None',
  Created = 'Created',
  InManagerReview = 'InManagerReview',
  InEmployeeReview = 'InEmployeeReview',
  Published = 'Published',
  ManagerRejected = 'ManagerRejected',
  EmployeeRejected = 'EmployeeRejected',
  NoTafRequired = 'NoTafRequired',
  InBusinessPartnerReview = 'InBusinessPartnerReview',
  BusinessPartnerRejected = 'BusinessPartnerRejected'
}
export enum IncentiveRecordsAssignmentLetterModelTypeEnum {
  Other = 'Other',
  AdherenceLetter = 'AdherenceLetter',
  TargetAgreementForm = 'TargetAgreementForm',
  TargetAchievementForm = 'TargetAchievementForm',
  MeritAllocation = 'MeritAllocation',
  SpecialPaymentLetter = 'SpecialPaymentLetter',
  RestructuringCalculation = 'RestructuringCalculation',
  RestructuringLetter = 'RestructuringLetter'
}
