import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IncentivePartnerReassignmentsFilterOptions } from '@coin/shared/util-enums';
import { FieldValuesPage, GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams, ReassignmentService } from '../generated';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

@Injectable()
export class ReassignmentsFieldValuesService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private reassignmentService: ReassignmentService
  ) {}

  public getFieldValues(page: number, size: number, targetProperty: IncentivePartnerReassignmentsFilterOptions, searchText?: string): Observable<FieldValuesPage> {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot get field values without selected season');
    }
    const mappedTargetProperty = IncentivePartnerReassignmentsFilterOptions[targetProperty];
    const args: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams = {
      seasonId: selectedSeason.id,
      pagingPage: page + 1,
      pagingSize: size,
      queryTargetProperty: mappedTargetProperty
    };
    args[`query${mappedTargetProperty}`] = searchText;
    return this.reassignmentService.getSeasonsIncentiveSeasonIdPartnerV1Reassignments(args);
  }
}
