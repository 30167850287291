@if (contentEdit && item) {
  <div class="expand-links-edit">
    <coin-v2-text-input [label]="'cms.content-pages.headline-text' | translate" [(ngModel)]="item.headline" [placeholder]="'cms.content-pages.headline-text' | translate" />
    <coin-v2-text-input [label]="'cms.content-pages.icon' | translate" [(ngModel)]="item.icon" [placeholder]="'cms.content-pages.icon' | translate" />
    <div class="expand-links__amount">
      <coin-v2-number-input
        [label]="'cms.content-pages.amount-links' | translate"
        aria-label="icon"
        [(ngModel)]="item.amountLinks"
        [placeholder]="'cms.content-pages.amount-links' | translate" />
      <coin-action-button class="primary" (click)="applyAmountLinks()" [noicon]="true">Apply amount links</coin-action-button>
    </div>
  </div>
}

@if (item) {
  <div class="expand-links-main">
    <div class="expand-links-box" (click)="boxExpanded = !boxExpanded">
      <div class="expand-links-text">
        @if (item.icon) {
          <span class="material-icons-outlined">{{ item.icon }}</span>
        }
        <p>{{ item.headline }}</p>
        <span class="material-icons-outlined">expand_more</span>
      </div>
    </div>
    <div class="expand-box-linkcontent" [ngClass]="{ 'expand-box-linkcontent--expanded': boxExpanded || contentEdit }">
      <coin-v2-text-input [label]="'content-pages.search' | translate" [(ngModel)]="expandSearch" (input)="searchAllLinks($event)" [placeholder]="'content-pages.search' | translate" />
      <ngx-simplebar class="content-container primary-scrollbar-vertical simple-table no-space">
        @for (linkItem of item.allLinks; track linkItem; let i = $index) {
          <div @shrinkExpandAll class="expand-links-item">
            <img aria-label="download icon" [src]="imagePath + 'coin/images/originals/download_icon_SE.png'" />
            @if (contentEdit) {
              <coin-v2-text-input [label]="'cms.content-pages.link-text' | translate" [(ngModel)]="linkItem.text" />
            }
            @if (contentEdit) {
              <coin-v2-text-input [label]="'cms.content-pages.link-href' | translate" [(ngModel)]="linkItem.link" [placeholder]="'cms.content-pages.link-href-placeholder' | translate" />
            }
            @if (contentEdit) {
              <coin-action-button class="secondary button-spacing" (click)="deleteItem(i)" [noicon]="true">
                <span class="material-icons">clear</span>
              </coin-action-button>
            }
            @if (!contentEdit) {
              <a (click)="openLink(linkItem.link)" class="flex-one text-black" target="_blank">{{ linkItem.text }}</a>
            }
          </div>
        }
      </ngx-simplebar>
    </div>
  </div>
}
