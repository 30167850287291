/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel,
  HeadcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel,
  HeadcountOverviewInformationsInformationModel,
  HeadcountOverviewReportsReportModel,
  SharedFieldValuesModel,
  SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel,
  SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface HeadcountOverviewServiceGetDetailsTeamMembersRequestParams {
  id: string;
  startDate: string;
  endDate: string;
}

export interface HeadcountOverviewServiceGetReportsRequestParams {
  /** The organisation of which a report should be returned of. */
  orgCode: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryDepartmentsRequestParams {
  /** The organisation of which the departments should be returned of. */
  orgCode: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  type: 0 | 1;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryFieldValuesTeamMembersRequestParams {
  field: string;
  /** The organisation of which the team members should be returned of. */
  department: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  search?: string;
  /** The are code of the team member to filter for. */
  are?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  orgCode?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  orgCodeStartsWith?: string;
  /** The inclusive time at which to start aggregating headcounts. */
  businessArea?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  region?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  country?: Array<string>;
  /** The inclusive time at which to end aggregating headcounts. */
  location?: Array<string>;
  countryCostType?: Array<'BestCost' | 'HighCost'>;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryTeamMembersRequestParams {
  /** The organisation of which the team members should be returned of. */
  department: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  /** The are code of the team member to filter for. */
  are?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  orgCode?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  orgCodeStartsWith?: string;
  /** The inclusive time at which to start aggregating headcounts. */
  businessArea?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  region?: Array<string>;
  /** The inclusive time at which to start aggregating headcounts. */
  country?: Array<string>;
  /** The inclusive time at which to end aggregating headcounts. */
  location?: Array<string>;
  countryCostType?: Array<'BestCost' | 'HighCost'>;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface HeadcountOverviewServiceUpdateAvailabilitiesTeamMembersRequestParams {
  id: string;
  model?: Array<HeadcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel>;
}

@Injectable({
  providedIn: 'root'
})
export class HeadcountOverviewService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getDetailsTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getDetailsTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling getDetailsTeamMembers.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/team-members/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/details`;
    return this.httpClient.request<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInformations(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewInformationsInformationModel>;
  public getInformations(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewInformationsInformationModel>>;
  public getInformations(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewInformationsInformationModel>>;
  public getInformations(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/informations`;
    return this.httpClient.request<HeadcountOverviewInformationsInformationModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getReports(
    requestParameters?: HeadcountOverviewServiceGetReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewReportsReportModel>;
  public getReports(
    requestParameters?: HeadcountOverviewServiceGetReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewReportsReportModel>>;
  public getReports(
    requestParameters?: HeadcountOverviewServiceGetReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewReportsReportModel>>;
  public getReports(
    requestParameters?: HeadcountOverviewServiceGetReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orgCode = requestParameters?.orgCode;
    if (orgCode === null || orgCode === undefined) {
      throw new Error('Required parameter orgCode was null or undefined when calling getReports.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getReports.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling getReports.');
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orgCode !== undefined && orgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCode, 'OrgCode');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/reports`;
    return this.httpClient.request<HeadcountOverviewReportsReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryDepartments(
    requestParameters?: HeadcountOverviewServiceQueryDepartmentsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>;
  public queryDepartments(
    requestParameters?: HeadcountOverviewServiceQueryDepartmentsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>>;
  public queryDepartments(
    requestParameters?: HeadcountOverviewServiceQueryDepartmentsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>>;
  public queryDepartments(
    requestParameters?: HeadcountOverviewServiceQueryDepartmentsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orgCode = requestParameters?.orgCode;
    if (orgCode === null || orgCode === undefined) {
      throw new Error('Required parameter orgCode was null or undefined when calling queryDepartments.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryDepartments.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryDepartments.');
    }
    const type = requestParameters?.type;
    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling queryDepartments.');
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orgCode !== undefined && orgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCode, 'OrgCode');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (type !== undefined && type !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>type, 'Type');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/departments`;
    return this.httpClient.request<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValuesTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedFieldValuesModel>;
  public queryFieldValuesTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedFieldValuesModel>>;
  public queryFieldValuesTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedFieldValuesModel>>;
  public queryFieldValuesTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const field = requestParameters?.field;
    if (field === null || field === undefined) {
      throw new Error('Required parameter field was null or undefined when calling queryFieldValuesTeamMembers.');
    }
    const department = requestParameters?.department;
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling queryFieldValuesTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryFieldValuesTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryFieldValuesTeamMembers.');
    }
    const search = requestParameters?.search;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgCodeStartsWith = requestParameters?.orgCodeStartsWith;
    const businessArea = requestParameters?.businessArea;
    const region = requestParameters?.region;
    const country = requestParameters?.country;
    const location = requestParameters?.location;
    const countryCostType = requestParameters?.countryCostType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
    }
    if (department !== undefined && department !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>department, 'Department');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgCodeStartsWith !== undefined && orgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCodeStartsWith, 'OrgCodeStartsWith');
    }
    if (businessArea) {
      businessArea.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'BusinessArea');
      });
    }
    if (region) {
      region.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Region');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (location) {
      location.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Location');
      });
    }
    if (countryCostType) {
      countryCostType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CountryCostType');
      });
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/field-values/headcount/overview/team-members/${this.configuration.encodeParam({ name: 'field', value: field, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedFieldValuesModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const department = requestParameters?.department;
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling queryTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryTeamMembers.');
    }
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgCodeStartsWith = requestParameters?.orgCodeStartsWith;
    const businessArea = requestParameters?.businessArea;
    const region = requestParameters?.region;
    const country = requestParameters?.country;
    const location = requestParameters?.location;
    const countryCostType = requestParameters?.countryCostType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (department !== undefined && department !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>department, 'Department');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgCodeStartsWith !== undefined && orgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCodeStartsWith, 'OrgCodeStartsWith');
    }
    if (businessArea) {
      businessArea.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'BusinessArea');
      });
    }
    if (region) {
      region.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Region');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (location) {
      location.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Location');
      });
    }
    if (countryCostType) {
      countryCostType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CountryCostType');
      });
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/team-members`;
    return this.httpClient.request<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateAvailabilitiesTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>;
  public updateAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateAvailabilitiesTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateAvailabilitiesTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateAvailabilitiesTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateAvailabilitiesTeamMembers.');
    }
    const model = requestParameters?.model;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (model) {
      model.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'model');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/team-members/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/availabilities`;
    return this.httpClient.request<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
