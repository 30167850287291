@if (card) {
  <div class="container" [class.small]="small">
    <div class="top" [ngStyle]="{ 'background-image': 'url(' + card?.backgroundUrl + ')', width: modifiedWidth ? 'calc(100% - 28px)' : '100%' }">
      @if (!contentEdit) {
        <h4 [innerHTML]="card.headline"></h4>
      }
      @if (contentEdit) {
        <h4 [contentEditable]="contentEdit" [textContent]="card?.headline" (input)="card.headline = $event.target.textContent"></h4>
      }
      @if (card?.icon) {
        <div class="icon-wrapper">
          <div class="icon" [ngStyle]="{ 'background-image': 'url(' + iconUrl + ')' }"></div>
        </div>
      }
      @if (contentEdit) {
        <coin-action-button (click)="editPicture(card)" class="secondary cms-card-change-picture" svgIcon="edit" [noicon]="true">{{
          'cms.content-pages.change-image-button' | translate
        }}</coin-action-button>
      }
    </div>
    <div class="bottom">
      @if (!contentEdit) {
        <p [innerHTML]="card?.text"></p>
      }
      @if (contentEdit) {
        <p [contentEditable]="contentEdit" [textContent]="card?.text" (input)="card.text = $event.target.textContent"></p>
      }
      @if (!contentEdit && card?.button.name && card.button.url) {
        <coin-action-button class="secondary" (click)="onButtonClick(card.button)">{{ card.button?.name }}</coin-action-button>
      }
      @if (contentEdit && card?.button) {
        <coin-action-button>
          <span class="secondary cursor-text" [contentEditable]="contentEdit" [textContent]="card?.button.name" (input)="card.button.name = $event.target.textContent"></span>
        </coin-action-button>
      }

      @if (contentEdit) {
        <div class="flex-one"></div>
      }
      @if (contentEdit) {
        <coin-v2-text-input [label]="'cms.content-pages.button-link' | translate" [(ngModel)]="card.button.url" [placeholder]="'https://...'" />
      }
    </div>
  </div>
}
