import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { IncentivePartnerService } from '@coin/shared/data-access';
import { IncentiveSupportComponentState } from './incentive-support.component.state';
import { Reassignment } from '../generated';

@Injectable()
export class MonitoringSearchService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private incentiveMonitoringService: IncentivePartnerService
  ) {}

  getSearchItemsByText(text: string, page: number): Observable<Reassignment[]> {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot load records without selected season');
    }
    return this.incentiveMonitoringService
      .queryMonitoring({
        seasonId: selectedSeason.id,
        pagingPage: page,
        pagingSize: 50
      })
      .pipe(
        map(result => result.content),
        // Return empty list in case of an error to prevent an error in the view
        catchError(() => of([]))
      );
  }
}
