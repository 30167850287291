<coin-employee-picture [gid]="comment?.employee?.gid" size="large"></coin-employee-picture>
<div class="comment-text">
  <div class="name-and-time">
    <p class="bold">{{ comment?.employee | employeeName }}</p>
    <p class="bold time">{{ comment?.timestamp | date: 'HH:mm' }} UTC</p>
  </div>
  <p class="gid">GID: {{ comment?.employee?.gid }}</p>
  <p class="message">
    {{ comment?.message }}
  </p>
</div>
