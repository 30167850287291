<div class="comments-container">
  @for (commentGroup of commentsByDate() | keyvalue: sortByDateAscending; track commentGroup.key) {
    @for (comment of commentGroup.value; track comment.timestamp) {
      <coin-v2-comment [comment]="comment"></coin-v2-comment>
    }
    <div class="date-divider">
      <coin-v2-divider orientation="horizontal" color="dark"></coin-v2-divider>
      <div class="date-indicator-panel">
        <p class="date-indicator">{{ commentGroup.key }}</p>
      </div>
    </div>
  }
</div>
<div class="input-section">
  <coin-v2-text-area (keydown.enter)="$event.preventDefault(); send()" [formControl]="textInput" [placeholder]="'general.enter-comment' | translate"></coin-v2-text-area>
  <coin-v2-button (click)="send()" [disabled]="!textInput.value" iconPos="right" type="primary" icon="send" text="Send"></coin-v2-button>
</div>
