/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel {
  seasonId?: string;
  seasonType?: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum | null;
  templateId?: string;
  letterType?: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum;
  numberFormatCountryIso?: string | null;
}
export enum SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum {
  Unknown = 'Unknown',
  Sog = 'SOG',
  Gbr = 'GBR',
  Merit = 'Merit',
  IncentiveV2 = 'IncentiveV2',
  SpecialPaymentCash = 'SpecialPaymentCash',
  SpecialPaymentEquity = 'SpecialPaymentEquity',
  PositionEvaluation = 'PositionEvaluation',
  Restructuring = 'Restructuring'
}
export enum SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum {
  Other = 'Other',
  AdherenceLetter = 'AdherenceLetter',
  TargetAgreementForm = 'TargetAgreementForm',
  TargetAchievementForm = 'TargetAchievementForm',
  MeritAllocation = 'MeritAllocation',
  SpecialPaymentLetter = 'SpecialPaymentLetter',
  RestructuringCalculation = 'RestructuringCalculation',
  RestructuringLetter = 'RestructuringLetter'
}
