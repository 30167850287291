<div class="container">
  <div>
    <div class="image" [class.edit]="contentEdit" [style.background-image]="'url(' + item?.content?.img + ')'">
      @if (contentEdit) {
        <coin-action-button (click)="editPicture()" class="secondary" svgIcon="edit" [noicon]="true">
          {{ 'cms.emails.change-image-button' | translate }}
        </coin-action-button>
      }
      @if (item?.content && contentEdit) {
        <coin-v2-text-input [(ngModel)]="item.content.link" [label]="'cms.image-url' | translate"></coin-v2-text-input>
      }
    </div>
    @if (contentEdit) {
      <coin-v2-checkbox [(ngModel)]="item.inverted" [label]="'cms.emails.inverted-checkbox' | translate" class="inverse-checkbox"> </coin-v2-checkbox>
    }
  </div>

  <app-html-editor [showAdditionalEditOptions]="showAdditionalEditOptions" [contentEdit]="contentEdit" [isContentText]="true" [item]="item"></app-html-editor>
</div>
