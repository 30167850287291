@if (item) {
  <div>
    @if (item.headline && !contentEdit) {
      <h3 style="margin-bottom: 20px">{{ item.headline }}</h3>
    }
    @if (contentEdit) {
      <coin-v2-text-input [label]="'cms.content-pages.headline' | translate" [(ngModel)]="item.headline" [placeholder]="'Headline'" />
    }
    <!-- <h3 style="margin-bottom: 20px;" *ngIf="item.headline && contentEdit" [contentEditable]="contentEdit" [textContent]="item.headline" (input)="item.headline = $event.target.textContent ? $event.target.textContent : 'headline'"></h3> -->
    @if (contentEdit) {
      <app-html-editor [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
    }
    @if (!contentEdit) {
      <p [ngStyle]="{ 'column-count': item.columns ? item.columns : 1 }" style="column-gap: 20px" [innerHTML]="item.text"></p>
    }
    <!-- <p [ngStyle]="{'column-count': item.columns ? item.columns : 1}" *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent ? $event.target.textContent : 'text'" style="column-gap: 20px;"></p> -->
  </div>
}
@if (contentEdit && item) {
  <div style="margin-top: 20px">
    <coin-v2-number-input
      [label]="'cms.columns' | translate"
      aria-label="amount columns"
      [(ngModel)]="item.columns"
      [placeholder]="'cms.amount-columns' | translate"
      [disabled]="!contentEdit" />
  </div>
}
