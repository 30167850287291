import { CommonModule, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '@coin/shared/util-models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { V2ButtonComponent } from '../button/v2-button.component';
import { V2DividerComponent } from '../divider/v2-divider.component';
import { V2TextAreaComponent } from '../text-area/v2-text-area.component';
import { V2CommentComponent } from './comment/v2-comment.component';

// TODO: specify interface
export interface Comment {
  employee: Employee;
  message: string;
  timestamp: Date | string | number;
}

type CommentsByDate = { [date: string]: Comment[] };

@Component({
  selector: 'coin-v2-comments',
  standalone: true,
  imports: [CommonModule, V2CommentComponent, V2ButtonComponent, TranslateModule, ReactiveFormsModule, V2TextAreaComponent, V2DividerComponent],
  templateUrl: './v2-comments.component.html',
  styleUrl: './v2-comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2CommentsComponent {
  comments = input.required<Comment[]>();
  @Output() sendComment = new EventEmitter<string>();

  private translate = inject(TranslateService);

  protected textInput = new FormControl('');

  protected commentsByDate = computed(() => {
    const comments = this.comments() ?? [];
    return [...comments]
      .sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()) // sort comments by newest first
      .reduce<CommentsByDate>((commentsByDate, comment) => {
        const isToday = moment().utc().isSame(comment.timestamp, 'day');
        const weekDay = isToday ? `[${this.translate.instant('general.today')}]` : 'dddd';
        const dateOfComment = moment(comment.timestamp).format(`${weekDay}, MM/DD/YYYY`);
        commentsByDate[dateOfComment] ??= [];
        commentsByDate[dateOfComment].push(comment);
        return commentsByDate;
      }, {});
  });

  // sort days by oldest first --> displays newest at the bottom, because of "flex-direction: column-reverse"
  protected sortByDateAscending = (a: KeyValue<string, Comment[]>, b: KeyValue<string, Comment[]>): number => {
    return moment(b.value[0].timestamp).unix() - moment(a.value[0].timestamp).unix();
  };

  protected send() {
    if (!this.textInput.value) return;
    this.sendComment.emit(this.textInput.value);
    this.textInput.setValue('');
  }
}
