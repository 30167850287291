/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcessStep } from './process-step';
import { AffectedService } from './affected-service';
import { OrganisationManagementOrganisationMetadata } from './organisation-management-organisation-metadata';

export interface TransactionStatusOfOrganisationManagementOrganisationMetadata {
  affectedServices?: Array<AffectedService> | null;
  transactionId?: string;
  entityId?: string;
  startedAt?: string;
  lastSuccessAt?: string | null;
  updatedAt?: string;
  finishedAt?: string;
  state?: TransactionStatusOfOrganisationManagementOrganisationMetadataStateEnum;
  progressCount?: number;
  totalCount?: number;
  metadata?: OrganisationManagementOrganisationMetadata;
  processTitle?: string | null;
  processType?: string | null;
  parentTransactionId?: string;
  processSteps?: Array<ProcessStep> | null;
  startedById?: string | null;
  startedByEmulatorId?: string | null;
  readonly processStepsCount?: number;
  readonly id?: string;
}
export enum TransactionStatusOfOrganisationManagementOrganisationMetadataStateEnum {
  Started = 'Started',
  Running = 'Running',
  Finished = 'Finished',
  Published = 'Published',
  Failed = 'Failed',
  Cancelled = 'Cancelled'
}
