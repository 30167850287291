import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeNamePipe } from '@coin/shared/util-pipes';
import { Comment } from '../v2-comments.component';
import { EmployeePictureComponent } from '../../employee-picture/employee-picture.component';

@Component({
  selector: 'coin-v2-comment',
  standalone: true,
  imports: [CommonModule, EmployeeNamePipe, EmployeePictureComponent],
  templateUrl: './v2-comment.component.html',
  styleUrl: './v2-comment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2CommentComponent {
  @Input({ required: true }) comment: Comment;
}
