<div class="container">
  <div class="search">
    <coin-v2-text-input
      matFormFieldClass="shadow"
      [(ngModel)]="searchEmployee"
      (input)="searchInputChanged()"
      placeholder="{{ 'main-menu.directs-search' | translate }}"
      [label]="'general.search' | translate"
      icon="search">
    </coin-v2-text-input>
  </div>

  <ngx-simplebar class="content-container primary-scrollbar-vertical no-space">
    @if (displayedEmployeeList?.length > 0) {
      <div class="directs-overview-scroll-viewport">
        @for (employee of displayedEmployeeList; track employee) {
          <coin-siemens-energy-employee-preview class="directs-overview-elements" (click)="goToDocumentCenter(employee)" [employee]="employee">
          </coin-siemens-energy-employee-preview>
        }
      </div>
    } @else {
      <p>{{ 'main-menu.no-directs-found' | translate }}</p>
    }
  </ngx-simplebar>
</div>
