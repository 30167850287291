/**
 * RestructuringCustomerAPI
 * API for Restructuring customer module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: tim.weiss@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { RestructuringCalculateRestructuringModel } from '../model/restructuring-calculate-restructuring-model.model';
// @ts-ignore
import { RestructuringCalculationModel } from '../model/restructuring-calculation-model.model';
// @ts-ignore
import { RestructuringCreateRestructuringRequestReportModel } from '../model/restructuring-create-restructuring-request-report-model.model';
// @ts-ignore
import { RestructuringDashboardModel } from '../model/restructuring-dashboard-model.model';
// @ts-ignore
import { RestructuringReportingHeaderModel } from '../model/restructuring-reporting-header-model.model';
// @ts-ignore
import { RestructuringRequestModel } from '../model/restructuring-request-model.model';
// @ts-ignore
import { RestructuringRequestReportModel } from '../model/restructuring-request-report-model.model';
// @ts-ignore
import { RestructuringSaveRestructuringCalculationModel } from '../model/restructuring-save-restructuring-calculation-model.model';
// @ts-ignore
import { RestructuringSeasonLightModel } from '../model/restructuring-season-light-model.model';
// @ts-ignore
import { RestructuringUpdateRestructuringRequestModel } from '../model/restructuring-update-restructuring-request-model.model';
// @ts-ignore
import { SharedFieldItemModel } from '../model/shared-field-item-model.model';
// @ts-ignore
import { SharedPageModelOfRestructuringActivityStreamModelOfString } from '../model/shared-page-model-of-restructuring-activity-stream-model-of-string.model';
// @ts-ignore
import { SharedPageModelOfRestructuringEmployeeSnapshotLightModel } from '../model/shared-page-model-of-restructuring-employee-snapshot-light-model.model';
// @ts-ignore
import { SharedPageModelOfRestructuringFieldValues } from '../model/shared-page-model-of-restructuring-field-values.model';
// @ts-ignore
import { SharedPageModelOfRestructuringRequestModel } from '../model/shared-page-model-of-restructuring-request-model.model';
// @ts-ignore
import { SharedPageModelOfRestructuringRequestReportModel } from '../model/shared-page-model-of-restructuring-request-report-model.model';
// @ts-ignore
import { SharedPageModelOfRestructuringString } from '../model/shared-page-model-of-restructuring-string.model';
// @ts-ignore
import { TransactionStatusOfRestructuringLetterBatchMetadata } from '../model/transaction-status-of-restructuring-letter-batch-metadata.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AttributesQueryRequestParams {
    id: string;
}

export interface EmployeeSnapshotsGetActiveSeasonsRequestParams {
    employeeId: string;
}

export interface EmployeeSnapshotsQueryRequestParams {
    searchString?: string;
    includeManagerInformation?: boolean;
    includeAttributes?: boolean;
    queryCountry?: Array<string>;
    queryLegalEntity?: Array<string>;
    queryContractStatus?: Array<string>;
    queryGid?: string;
    queryFullName?: string;
    queryOrgCode?: string;
    queryAttributes?: { [key: string]: string; };
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
}

export interface RestructuringCalculationCalculateRequestParams {
    restructuringCalculateRestructuringModel?: RestructuringCalculateRestructuringModel;
}

export interface RestructuringCalculationSaveCalculationRequestParams {
    restructuringSaveRestructuringCalculationModel?: RestructuringSaveRestructuringCalculationModel;
}

export interface RestructuringReportingCreateReportItemRequestParams {
    requestId: string;
    restructuringCreateRestructuringRequestReportModel?: RestructuringCreateRestructuringRequestReportModel;
}

export interface RestructuringReportingGetAssignmentFieldValuesByCountryRequestParams {
    countryCode: string;
    assignmentId: string;
    searchString?: string;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
    queryAttributes?: { [key: string]: string; };
    queryAttributeByName?: { [key: string]: string; };
    queryAssignments?: { [key: string]: string; };
    queryGid?: string;
    queryFirstName?: string;
    queryLastName?: string;
    queryFullName?: string;
    queryOrgCodeStartsWith?: string;
    queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
    queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryFiscalYear?: Array<string>;
    queryAre?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgCode?: Array<string>;
    queryPlanId?: Array<string>;
    queryReportingDate?: string;
    queryUpdatedBy?: string;
    querySeasonId?: Array<string>;
    querySeasonTitle?: Array<string>;
    queryPlanTitle?: Array<string>;
    querySubState?: Array<string>;
}

export interface RestructuringReportingGetFieldValuesByCountryRequestParams {
    countryCode: string;
    fieldName: string;
    searchString?: string;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
    queryAttributes?: { [key: string]: string; };
    queryAttributeByName?: { [key: string]: string; };
    queryAssignments?: { [key: string]: string; };
    queryGid?: string;
    queryFirstName?: string;
    queryLastName?: string;
    queryFullName?: string;
    queryOrgCodeStartsWith?: string;
    queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
    queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryFiscalYear?: Array<string>;
    queryAre?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgCode?: Array<string>;
    queryPlanId?: Array<string>;
    queryReportingDate?: string;
    queryUpdatedBy?: string;
    querySeasonId?: Array<string>;
    querySeasonTitle?: Array<string>;
    queryPlanTitle?: Array<string>;
    querySubState?: Array<string>;
}

export interface RestructuringReportingGetHeadersRequestParams {
    countryCode: string;
}

export interface RestructuringReportingGetItemsByCountryRequestParams {
    countryCode: string;
    searchString?: string;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
    queryAttributes?: { [key: string]: string; };
    queryGid?: string;
    queryFirstName?: string;
    queryLastName?: string;
    queryFullName?: string;
    queryOrgCodeStartsWith?: string;
    queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
    queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryFiscalYear?: Array<string>;
    queryLegalEntity?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgCode?: Array<string>;
}

export interface RestructuringReportingGetItemsByRequestRequestParams {
    requestId: string;
    queryAttributes?: { [key: string]: string; };
    queryGid?: string;
    queryFirstName?: string;
    queryLastName?: string;
    queryFullName?: string;
    queryOrgCodeStartsWith?: string;
    queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
    queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryFiscalYear?: Array<string>;
    queryLegalEntity?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgCode?: Array<string>;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
}

export interface RestructuringReportingGetItemsBySeasonRequestParams {
    seasonId: string;
    searchString?: string;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'Asc' | 'Desc';
    sortingProperty?: string;
    queryAttributes?: { [key: string]: string; };
    queryGid?: string;
    queryFirstName?: string;
    queryLastName?: string;
    queryFullName?: string;
    queryOrgCodeStartsWith?: string;
    queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
    queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryFiscalYear?: Array<string>;
    queryLegalEntity?: Array<string>;
    queryCountry?: Array<string>;
    queryOrgCode?: Array<string>;
}

export interface RestructuringRequestCreateCalculationFileRequestParams {
    requestId: string;
}

export interface RestructuringRequestDeleteRequestParams {
    requestId: string;
}

export interface RestructuringRequestGetActivityStreamForRequestRequestParams {
    requestId: string;
    page?: number;
    size?: number;
}

export interface RestructuringRequestGetCalculationByRequestRequestParams {
    requestId: string;
}

export interface RestructuringRequestGetDashboardRequestParams {
    searchString?: string;
    fiscalYear?: string;
    queryCountry?: Array<string>;
    queryLegalEntity?: Array<string>;
    querySeasonId?: Array<string>;
    queryPlanId?: Array<string>;
    queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryLineManager?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: Array<string>;
    queryFiscalYear?: Array<string>;
}

export interface RestructuringRequestGetRequestRequestParams {
    requestId: string;
}

export interface RestructuringRequestQueryRequestParams {
    searchString?: string;
    fiscalYear?: string;
    queryCountry?: Array<string>;
    queryAre?: Array<string>;
    querySeasonId?: Array<string>;
    queryPlanId?: Array<string>;
    queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryLineManagerGid?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: Array<string>;
    queryFiscalYear?: Array<string>;
    pageablePage?: number;
    pageableSize?: number;
    sortingOrderBy?: 'asc' | 'desc';
    sortingProperty?: string;
}

export interface RestructuringRequestQueryFieldValueRequestParams {
    fieldName: string;
    searchString?: string;
    fiscalYear?: string;
    queryCountry?: Array<string>;
    queryLegalEntity?: Array<string>;
    querySeasonId?: Array<string>;
    queryPlanId?: Array<string>;
    queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryLineManager?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: Array<string>;
    queryFiscalYear?: Array<string>;
    pageablePage?: number;
    pageableSize?: number;
}

export interface RestructuringRequestQueryFieldValueWithIdRequestParams {
    fieldName: string;
    searchString?: string;
    fiscalYear?: string;
    queryCountry?: Array<string>;
    queryLegalEntity?: Array<string>;
    querySeasonId?: Array<string>;
    queryPlanId?: Array<string>;
    queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected'>;
    queryLineManager?: Array<string>;
    queryOrgCode?: Array<string>;
    queryOrgCodeStartsWith?: Array<string>;
    queryFiscalYear?: Array<string>;
}

export interface RestructuringRequestUpdateRequestRequestParams {
    restructuringUpdateRestructuringRequestModel?: RestructuringUpdateRestructuringRequestModel;
}


@Injectable({
  providedIn: 'root'
})
export class RestructuringService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attributesQuery(requestParameters: AttributesQueryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<Array<string>>;
    public attributesQuery(requestParameters: AttributesQueryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<Array<string>>>;
    public attributesQuery(requestParameters: AttributesQueryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<Array<string>>>;
    public attributesQuery(requestParameters: AttributesQueryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling attributesQuery.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/attributes/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/values`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeSnapshotsGetActiveSeasons(requestParameters: EmployeeSnapshotsGetActiveSeasonsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<Array<RestructuringSeasonLightModel>>;
    public employeeSnapshotsGetActiveSeasons(requestParameters: EmployeeSnapshotsGetActiveSeasonsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<Array<RestructuringSeasonLightModel>>>;
    public employeeSnapshotsGetActiveSeasons(requestParameters: EmployeeSnapshotsGetActiveSeasonsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<Array<RestructuringSeasonLightModel>>>;
    public employeeSnapshotsGetActiveSeasons(requestParameters: EmployeeSnapshotsGetActiveSeasonsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeSnapshotsGetActiveSeasons.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/employees/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/seasons`;
        return this.httpClient.request<Array<RestructuringSeasonLightModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeSnapshotsQuery(requestParameters: EmployeeSnapshotsQueryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringEmployeeSnapshotLightModel>;
    public employeeSnapshotsQuery(requestParameters: EmployeeSnapshotsQueryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringEmployeeSnapshotLightModel>>;
    public employeeSnapshotsQuery(requestParameters: EmployeeSnapshotsQueryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringEmployeeSnapshotLightModel>>;
    public employeeSnapshotsQuery(requestParameters: EmployeeSnapshotsQueryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const searchString = requestParameters.searchString;
        const includeManagerInformation = requestParameters.includeManagerInformation;
        const includeAttributes = requestParameters.includeAttributes;
        const queryCountry = requestParameters.queryCountry;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryGid = requestParameters.queryGid;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryAttributes = requestParameters.queryAttributes;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (includeManagerInformation !== undefined && includeManagerInformation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeManagerInformation, 'IncludeManagerInformation');
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeAttributes, 'IncludeAttributes');
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'Query.OrgCode');
        }
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/employees`;
        return this.httpClient.request<SharedPageModelOfRestructuringEmployeeSnapshotLightModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringCalculationCalculate(requestParameters: RestructuringCalculationCalculateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringCalculationModel>;
    public restructuringCalculationCalculate(requestParameters: RestructuringCalculationCalculateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringCalculationModel>>;
    public restructuringCalculationCalculate(requestParameters: RestructuringCalculationCalculateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringCalculationModel>>;
    public restructuringCalculationCalculate(requestParameters: RestructuringCalculationCalculateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const restructuringCalculateRestructuringModel = requestParameters.restructuringCalculateRestructuringModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/*+json',
            'application/json',
            'application/json-patch+json',
            'text/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/calculation/simulate`;
        return this.httpClient.request<RestructuringCalculationModel>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: restructuringCalculateRestructuringModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringCalculationSaveCalculation(requestParameters: RestructuringCalculationSaveCalculationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringCalculationModel>;
    public restructuringCalculationSaveCalculation(requestParameters: RestructuringCalculationSaveCalculationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringCalculationModel>>;
    public restructuringCalculationSaveCalculation(requestParameters: RestructuringCalculationSaveCalculationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringCalculationModel>>;
    public restructuringCalculationSaveCalculation(requestParameters: RestructuringCalculationSaveCalculationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const restructuringSaveRestructuringCalculationModel = requestParameters.restructuringSaveRestructuringCalculationModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/*+json',
            'application/json',
            'application/json-patch+json',
            'text/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/calculation`;
        return this.httpClient.request<RestructuringCalculationModel>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: restructuringSaveRestructuringCalculationModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingCreateReportItem(requestParameters: RestructuringReportingCreateReportItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringRequestReportModel>;
    public restructuringReportingCreateReportItem(requestParameters: RestructuringReportingCreateReportItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringRequestReportModel>>;
    public restructuringReportingCreateReportItem(requestParameters: RestructuringReportingCreateReportItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringRequestReportModel>>;
    public restructuringReportingCreateReportItem(requestParameters: RestructuringReportingCreateReportItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringReportingCreateReportItem.');
        }
        const restructuringCreateRestructuringRequestReportModel = requestParameters.restructuringCreateRestructuringRequestReportModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/*+json',
            'application/json',
            'application/json-patch+json',
            'text/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<RestructuringRequestReportModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: restructuringCreateRestructuringRequestReportModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetAssignmentFieldValuesByCountry(requestParameters: RestructuringReportingGetAssignmentFieldValuesByCountryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SharedPageModelOfRestructuringString>;
    public restructuringReportingGetAssignmentFieldValuesByCountry(requestParameters: RestructuringReportingGetAssignmentFieldValuesByCountryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringString>>;
    public restructuringReportingGetAssignmentFieldValuesByCountry(requestParameters: RestructuringReportingGetAssignmentFieldValuesByCountryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringString>>;
    public restructuringReportingGetAssignmentFieldValuesByCountry(requestParameters: RestructuringReportingGetAssignmentFieldValuesByCountryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const countryCode = requestParameters.countryCode;
        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling restructuringReportingGetAssignmentFieldValuesByCountry.');
        }
        const assignmentId = requestParameters.assignmentId;
        if (assignmentId === null || assignmentId === undefined) {
            throw new Error('Required parameter assignmentId was null or undefined when calling restructuringReportingGetAssignmentFieldValuesByCountry.');
        }
        const searchString = requestParameters.searchString;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryAttributes = requestParameters.queryAttributes;
        const queryAttributeByName = requestParameters.queryAttributeByName;
        const queryAssignments = requestParameters.queryAssignments;
        const queryGid = requestParameters.queryGid;
        const queryFirstName = requestParameters.queryFirstName;
        const queryLastName = requestParameters.queryLastName;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryRequestState = requestParameters.queryRequestState;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const queryAre = requestParameters.queryAre;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryPlanId = requestParameters.queryPlanId;
        const queryReportingDate = requestParameters.queryReportingDate;
        const queryUpdatedBy = requestParameters.queryUpdatedBy;
        const querySeasonId = requestParameters.querySeasonId;
        const querySeasonTitle = requestParameters.querySeasonTitle;
        const queryPlanTitle = requestParameters.queryPlanTitle;
        const querySubState = requestParameters.querySubState;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributeByName, 'Query.AttributeByName');
        }
        if (queryAssignments !== undefined && queryAssignments !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAssignments, 'Query.Assignments');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFirstName !== undefined && queryFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstName, 'Query.FirstName');
        }
        if (queryLastName !== undefined && queryLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastName, 'Query.LastName');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryRequestState) {
            queryRequestState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.RequestState');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Are');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryReportingDate !== undefined && queryReportingDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryReportingDate, 'Query.ReportingDate');
        }
        if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryUpdatedBy, 'Query.UpdatedBy');
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (querySeasonTitle) {
            querySeasonTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonTitle');
            })
        }
        if (queryPlanTitle) {
            queryPlanTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanTitle');
            })
        }
        if (querySubState) {
            querySubState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SubState');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({name: "countryCode", value: countryCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/assignment-field-values/${this.configuration.encodeParam({name: "assignmentId", value: assignmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringString>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetFieldValuesByCountry(requestParameters: RestructuringReportingGetFieldValuesByCountryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SharedPageModelOfRestructuringString>;
    public restructuringReportingGetFieldValuesByCountry(requestParameters: RestructuringReportingGetFieldValuesByCountryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringString>>;
    public restructuringReportingGetFieldValuesByCountry(requestParameters: RestructuringReportingGetFieldValuesByCountryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringString>>;
    public restructuringReportingGetFieldValuesByCountry(requestParameters: RestructuringReportingGetFieldValuesByCountryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const countryCode = requestParameters.countryCode;
        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling restructuringReportingGetFieldValuesByCountry.');
        }
        const fieldName = requestParameters.fieldName;
        if (fieldName === null || fieldName === undefined) {
            throw new Error('Required parameter fieldName was null or undefined when calling restructuringReportingGetFieldValuesByCountry.');
        }
        const searchString = requestParameters.searchString;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryAttributes = requestParameters.queryAttributes;
        const queryAttributeByName = requestParameters.queryAttributeByName;
        const queryAssignments = requestParameters.queryAssignments;
        const queryGid = requestParameters.queryGid;
        const queryFirstName = requestParameters.queryFirstName;
        const queryLastName = requestParameters.queryLastName;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryRequestState = requestParameters.queryRequestState;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const queryAre = requestParameters.queryAre;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryPlanId = requestParameters.queryPlanId;
        const queryReportingDate = requestParameters.queryReportingDate;
        const queryUpdatedBy = requestParameters.queryUpdatedBy;
        const querySeasonId = requestParameters.querySeasonId;
        const querySeasonTitle = requestParameters.querySeasonTitle;
        const queryPlanTitle = requestParameters.queryPlanTitle;
        const querySubState = requestParameters.querySubState;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributeByName, 'Query.AttributeByName');
        }
        if (queryAssignments !== undefined && queryAssignments !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAssignments, 'Query.Assignments');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFirstName !== undefined && queryFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstName, 'Query.FirstName');
        }
        if (queryLastName !== undefined && queryLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastName, 'Query.LastName');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryRequestState) {
            queryRequestState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.RequestState');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Are');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryReportingDate !== undefined && queryReportingDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryReportingDate, 'Query.ReportingDate');
        }
        if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryUpdatedBy, 'Query.UpdatedBy');
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (querySeasonTitle) {
            querySeasonTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonTitle');
            })
        }
        if (queryPlanTitle) {
            queryPlanTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanTitle');
            })
        }
        if (querySubState) {
            querySubState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SubState');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({name: "countryCode", value: countryCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/field-values/${this.configuration.encodeParam({name: "fieldName", value: fieldName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringString>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetHeaders(requestParameters: RestructuringReportingGetHeadersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<Array<RestructuringReportingHeaderModel>>;
    public restructuringReportingGetHeaders(requestParameters: RestructuringReportingGetHeadersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<Array<RestructuringReportingHeaderModel>>>;
    public restructuringReportingGetHeaders(requestParameters: RestructuringReportingGetHeadersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<Array<RestructuringReportingHeaderModel>>>;
    public restructuringReportingGetHeaders(requestParameters: RestructuringReportingGetHeadersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const countryCode = requestParameters.countryCode;
        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling restructuringReportingGetHeaders.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/headers/${this.configuration.encodeParam({name: "countryCode", value: countryCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<RestructuringReportingHeaderModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetItemsByCountry(requestParameters: RestructuringReportingGetItemsByCountryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringRequestReportModel>;
    public restructuringReportingGetItemsByCountry(requestParameters: RestructuringReportingGetItemsByCountryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsByCountry(requestParameters: RestructuringReportingGetItemsByCountryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsByCountry(requestParameters: RestructuringReportingGetItemsByCountryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const countryCode = requestParameters.countryCode;
        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling restructuringReportingGetItemsByCountry.');
        }
        const searchString = requestParameters.searchString;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryAttributes = requestParameters.queryAttributes;
        const queryGid = requestParameters.queryGid;
        const queryFirstName = requestParameters.queryFirstName;
        const queryLastName = requestParameters.queryLastName;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryRequestState = requestParameters.queryRequestState;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgCode = requestParameters.queryOrgCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFirstName !== undefined && queryFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstName, 'Query.FirstName');
        }
        if (queryLastName !== undefined && queryLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastName, 'Query.LastName');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryRequestState) {
            queryRequestState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.RequestState');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({name: "countryCode", value: countryCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetItemsByRequest(requestParameters: RestructuringReportingGetItemsByRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringRequestReportModel>;
    public restructuringReportingGetItemsByRequest(requestParameters: RestructuringReportingGetItemsByRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsByRequest(requestParameters: RestructuringReportingGetItemsByRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsByRequest(requestParameters: RestructuringReportingGetItemsByRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringReportingGetItemsByRequest.');
        }
        const queryAttributes = requestParameters.queryAttributes;
        const queryGid = requestParameters.queryGid;
        const queryFirstName = requestParameters.queryFirstName;
        const queryLastName = requestParameters.queryLastName;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryRequestState = requestParameters.queryRequestState;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgCode = requestParameters.queryOrgCode;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFirstName !== undefined && queryFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstName, 'Query.FirstName');
        }
        if (queryLastName !== undefined && queryLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastName, 'Query.LastName');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryRequestState) {
            queryRequestState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.RequestState');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringReportingGetItemsBySeason(requestParameters: RestructuringReportingGetItemsBySeasonRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringRequestReportModel>;
    public restructuringReportingGetItemsBySeason(requestParameters: RestructuringReportingGetItemsBySeasonRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsBySeason(requestParameters: RestructuringReportingGetItemsBySeasonRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringRequestReportModel>>;
    public restructuringReportingGetItemsBySeason(requestParameters: RestructuringReportingGetItemsBySeasonRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const seasonId = requestParameters.seasonId;
        if (seasonId === null || seasonId === undefined) {
            throw new Error('Required parameter seasonId was null or undefined when calling restructuringReportingGetItemsBySeason.');
        }
        const searchString = requestParameters.searchString;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;
        const queryAttributes = requestParameters.queryAttributes;
        const queryGid = requestParameters.queryGid;
        const queryFirstName = requestParameters.queryFirstName;
        const queryLastName = requestParameters.queryLastName;
        const queryFullName = requestParameters.queryFullName;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryContractStatus = requestParameters.queryContractStatus;
        const queryRequestState = requestParameters.queryRequestState;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const queryCountry = requestParameters.queryCountry;
        const queryOrgCode = requestParameters.queryOrgCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }
        if (queryAttributes !== undefined && queryAttributes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAttributes, 'Query.Attributes');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'Query.Gid');
        }
        if (queryFirstName !== undefined && queryFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstName, 'Query.FirstName');
        }
        if (queryLastName !== undefined && queryLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastName, 'Query.LastName');
        }
        if (queryFullName !== undefined && queryFullName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFullName, 'Query.FullName');
        }
        if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
        }
        if (queryContractStatus) {
            queryContractStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.ContractStatus');
            })
        }
        if (queryRequestState) {
            queryRequestState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.RequestState');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/reporting/seasons/${this.configuration.encodeParam({name: "seasonId", value: seasonId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestCreateCalculationFile(requestParameters: RestructuringRequestCreateCalculationFileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TransactionStatusOfRestructuringLetterBatchMetadata>;
    public restructuringRequestCreateCalculationFile(requestParameters: RestructuringRequestCreateCalculationFileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TransactionStatusOfRestructuringLetterBatchMetadata>>;
    public restructuringRequestCreateCalculationFile(requestParameters: RestructuringRequestCreateCalculationFileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TransactionStatusOfRestructuringLetterBatchMetadata>>;
    public restructuringRequestCreateCalculationFile(requestParameters: RestructuringRequestCreateCalculationFileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringRequestCreateCalculationFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/calculation-file`;
        return this.httpClient.request<TransactionStatusOfRestructuringLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestDelete(requestParameters: RestructuringRequestDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<boolean>;
    public restructuringRequestDelete(requestParameters: RestructuringRequestDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public restructuringRequestDelete(requestParameters: RestructuringRequestDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public restructuringRequestDelete(requestParameters: RestructuringRequestDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringRequestDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<boolean>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestGetActivityStreamForRequest(requestParameters: RestructuringRequestGetActivityStreamForRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringActivityStreamModelOfString>;
    public restructuringRequestGetActivityStreamForRequest(requestParameters: RestructuringRequestGetActivityStreamForRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringActivityStreamModelOfString>>;
    public restructuringRequestGetActivityStreamForRequest(requestParameters: RestructuringRequestGetActivityStreamForRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringActivityStreamModelOfString>>;
    public restructuringRequestGetActivityStreamForRequest(requestParameters: RestructuringRequestGetActivityStreamForRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringRequestGetActivityStreamForRequest.');
        }
        const page = requestParameters.page;
        const size = requestParameters.size;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'Size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/activity-stream`;
        return this.httpClient.request<SharedPageModelOfRestructuringActivityStreamModelOfString>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestGetCalculationByRequest(requestParameters: RestructuringRequestGetCalculationByRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringCalculationModel>;
    public restructuringRequestGetCalculationByRequest(requestParameters: RestructuringRequestGetCalculationByRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringCalculationModel>>;
    public restructuringRequestGetCalculationByRequest(requestParameters: RestructuringRequestGetCalculationByRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringCalculationModel>>;
    public restructuringRequestGetCalculationByRequest(requestParameters: RestructuringRequestGetCalculationByRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringRequestGetCalculationByRequest.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/calculation`;
        return this.httpClient.request<RestructuringCalculationModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestGetDashboard(requestParameters: RestructuringRequestGetDashboardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringDashboardModel>;
    public restructuringRequestGetDashboard(requestParameters: RestructuringRequestGetDashboardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringDashboardModel>>;
    public restructuringRequestGetDashboard(requestParameters: RestructuringRequestGetDashboardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringDashboardModel>>;
    public restructuringRequestGetDashboard(requestParameters: RestructuringRequestGetDashboardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const searchString = requestParameters.searchString;
        const fiscalYear = requestParameters.fiscalYear;
        const queryCountry = requestParameters.queryCountry;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const querySeasonId = requestParameters.querySeasonId;
        const queryPlanId = requestParameters.queryPlanId;
        const queryState = requestParameters.queryState;
        const queryLineManager = requestParameters.queryLineManager;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryFiscalYear = requestParameters.queryFiscalYear;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (fiscalYear !== undefined && fiscalYear !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fiscalYear, 'FiscalYear');
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryState) {
            queryState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.State');
            })
        }
        if (queryLineManager) {
            queryLineManager.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LineManager');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryOrgCodeStartsWith) {
            queryOrgCodeStartsWith.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCodeStartsWith');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/dashboard`;
        return this.httpClient.request<RestructuringDashboardModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestGetRequest(requestParameters: RestructuringRequestGetRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringRequestModel>;
    public restructuringRequestGetRequest(requestParameters: RestructuringRequestGetRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringRequestModel>>;
    public restructuringRequestGetRequest(requestParameters: RestructuringRequestGetRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringRequestModel>>;
    public restructuringRequestGetRequest(requestParameters: RestructuringRequestGetRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const requestId = requestParameters.requestId;
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling restructuringRequestGetRequest.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<RestructuringRequestModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestQuery(requestParameters: RestructuringRequestQueryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringRequestModel>;
    public restructuringRequestQuery(requestParameters: RestructuringRequestQueryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringRequestModel>>;
    public restructuringRequestQuery(requestParameters: RestructuringRequestQueryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringRequestModel>>;
    public restructuringRequestQuery(requestParameters: RestructuringRequestQueryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const searchString = requestParameters.searchString;
        const fiscalYear = requestParameters.fiscalYear;
        const queryCountry = requestParameters.queryCountry;
        const queryAre = requestParameters.queryAre;
        const querySeasonId = requestParameters.querySeasonId;
        const queryPlanId = requestParameters.queryPlanId;
        const queryState = requestParameters.queryState;
        const queryLineManagerGid = requestParameters.queryLineManagerGid;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;
        const sortingOrderBy = requestParameters.sortingOrderBy;
        const sortingProperty = requestParameters.sortingProperty;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (fiscalYear !== undefined && fiscalYear !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fiscalYear, 'FiscalYear');
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryAre) {
            queryAre.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Are');
            })
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryState) {
            queryState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.State');
            })
        }
        if (queryLineManagerGid) {
            queryLineManagerGid.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LineManagerGid');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryOrgCodeStartsWith) {
            queryOrgCodeStartsWith.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCodeStartsWith');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'Sorting.OrderBy');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'Sorting.Property');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests`;
        return this.httpClient.request<SharedPageModelOfRestructuringRequestModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestQueryFieldValue(requestParameters: RestructuringRequestQueryFieldValueRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<SharedPageModelOfRestructuringFieldValues>;
    public restructuringRequestQueryFieldValue(requestParameters: RestructuringRequestQueryFieldValueRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<SharedPageModelOfRestructuringFieldValues>>;
    public restructuringRequestQueryFieldValue(requestParameters: RestructuringRequestQueryFieldValueRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<SharedPageModelOfRestructuringFieldValues>>;
    public restructuringRequestQueryFieldValue(requestParameters: RestructuringRequestQueryFieldValueRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const fieldName = requestParameters.fieldName;
        if (fieldName === null || fieldName === undefined) {
            throw new Error('Required parameter fieldName was null or undefined when calling restructuringRequestQueryFieldValue.');
        }
        const searchString = requestParameters.searchString;
        const fiscalYear = requestParameters.fiscalYear;
        const queryCountry = requestParameters.queryCountry;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const querySeasonId = requestParameters.querySeasonId;
        const queryPlanId = requestParameters.queryPlanId;
        const queryState = requestParameters.queryState;
        const queryLineManager = requestParameters.queryLineManager;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryFiscalYear = requestParameters.queryFiscalYear;
        const pageablePage = requestParameters.pageablePage;
        const pageableSize = requestParameters.pageableSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (fiscalYear !== undefined && fiscalYear !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fiscalYear, 'FiscalYear');
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryState) {
            queryState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.State');
            })
        }
        if (queryLineManager) {
            queryLineManager.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LineManager');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryOrgCodeStartsWith) {
            queryOrgCodeStartsWith.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCodeStartsWith');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }
        if (pageablePage !== undefined && pageablePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageablePage, 'Pageable.Page');
        }
        if (pageableSize !== undefined && pageableSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageableSize, 'Pageable.Size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/field-values/${this.configuration.encodeParam({name: "fieldName", value: fieldName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SharedPageModelOfRestructuringFieldValues>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestQueryFieldValueWithId(requestParameters: RestructuringRequestQueryFieldValueWithIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<Array<SharedFieldItemModel>>;
    public restructuringRequestQueryFieldValueWithId(requestParameters: RestructuringRequestQueryFieldValueWithIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<Array<SharedFieldItemModel>>>;
    public restructuringRequestQueryFieldValueWithId(requestParameters: RestructuringRequestQueryFieldValueWithIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<Array<SharedFieldItemModel>>>;
    public restructuringRequestQueryFieldValueWithId(requestParameters: RestructuringRequestQueryFieldValueWithIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const fieldName = requestParameters.fieldName;
        if (fieldName === null || fieldName === undefined) {
            throw new Error('Required parameter fieldName was null or undefined when calling restructuringRequestQueryFieldValueWithId.');
        }
        const searchString = requestParameters.searchString;
        const fiscalYear = requestParameters.fiscalYear;
        const queryCountry = requestParameters.queryCountry;
        const queryLegalEntity = requestParameters.queryLegalEntity;
        const querySeasonId = requestParameters.querySeasonId;
        const queryPlanId = requestParameters.queryPlanId;
        const queryState = requestParameters.queryState;
        const queryLineManager = requestParameters.queryLineManager;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgCodeStartsWith = requestParameters.queryOrgCodeStartsWith;
        const queryFiscalYear = requestParameters.queryFiscalYear;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (fiscalYear !== undefined && fiscalYear !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fiscalYear, 'FiscalYear');
        }
        if (queryCountry) {
            queryCountry.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.Country');
            })
        }
        if (queryLegalEntity) {
            queryLegalEntity.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LegalEntity');
            })
        }
        if (querySeasonId) {
            querySeasonId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.SeasonId');
            })
        }
        if (queryPlanId) {
            queryPlanId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.PlanId');
            })
        }
        if (queryState) {
            queryState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.State');
            })
        }
        if (queryLineManager) {
            queryLineManager.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.LineManager');
            })
        }
        if (queryOrgCode) {
            queryOrgCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCode');
            })
        }
        if (queryOrgCodeStartsWith) {
            queryOrgCodeStartsWith.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.OrgCodeStartsWith');
            })
        }
        if (queryFiscalYear) {
            queryFiscalYear.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Query.FiscalYear');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests/field-values-with-id/${this.configuration.encodeParam({name: "fieldName", value: fieldName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<SharedFieldItemModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restructuringRequestUpdateRequest(requestParameters: RestructuringRequestUpdateRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<RestructuringRequestModel>;
    public restructuringRequestUpdateRequest(requestParameters: RestructuringRequestUpdateRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpResponse<RestructuringRequestModel>>;
    public restructuringRequestUpdateRequest(requestParameters: RestructuringRequestUpdateRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<HttpEvent<RestructuringRequestModel>>;
    public restructuringRequestUpdateRequest(requestParameters: RestructuringRequestUpdateRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'text/plain', context?: HttpContext}): Observable<any> {
        const restructuringUpdateRestructuringRequestModel = requestParameters.restructuringUpdateRestructuringRequestModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'text/plain'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/*+json',
            'application/json',
            'application/json-patch+json',
            'text/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/restructuring/customer/v1/requests`;
        return this.httpClient.request<RestructuringRequestModel>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: restructuringUpdateRestructuringRequestModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
