/**
 * SuccessionManagementAPI
 * API for succession management module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: andre.weinkoetz@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AcknowledgeTalentRecommendation } from '../model/acknowledge-talent-recommendation.model';
// @ts-ignore
import { CreateTalentRecommendation } from '../model/create-talent-recommendation.model';
// @ts-ignore
import { DeleteTalentRecommendationModel } from '../model/delete-talent-recommendation-model.model';
// @ts-ignore
import { ErrorResponse } from '../model/error-response.model';
// @ts-ignore
import { FieldValuesPage } from '../model/field-values-page.model';
// @ts-ignore
import { RecommendationRatio } from '../model/recommendation-ratio.model';
// @ts-ignore
import { TalentRecommendation } from '../model/talent-recommendation.model';
// @ts-ignore
import { UpdateTalentRecommendation } from '../model/update-talent-recommendation.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DeleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalIdRequestParams {
    id: string;
    approvalId: string;
}

export interface DeleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalIdRequestParams {
    id: string;
    approvalId: string;
}

export interface DeleteSuccessionManagementPartnerV1TalentRecommendationsDeleteRequestParams {
    /** filters for talent pool title (va contains) */
    queryTalentPoolTitle?: Array<string>;
    /** multi-select filters for talent pool types */
    queryTalentPoolReferenceMetaTypes?: Array<string>;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** contains */
    queryGid?: string;
    /** OR filter clause for gid, firstname, lastname */
    querySearch?: string;
    /** filters for id of talent pool */
    queryTalentPoolId?: string;
    /** contains */
    queryOrgCode?: string;
    /** contains */
    queryOrgName?: string;
    /** equals */
    queryPlaceOfAction?: Array<string>;
    /** in list */
    queryOrgCodes?: Array<string>;
    /** in list */
    queryOrgNames?: Array<string>;
    /** enum - defaults for N-3 &amp; HR BPs */
    queryRecommendationState?: Array<string>;
    /** in list */
    queryAreCodes?: Array<string>;
    deleteTalentRecommendationModel?: DeleteTalentRecommendationModel;
}

export interface GetSuccessionManagementCustomerV1RatioFieldValuesRequestParams {
    queryTargetProperty?: string;
    queryOrgCode?: string;
    pagingPage?: number;
    pagingSize?: number;
}

export interface GetSuccessionManagementCustomerV1RecommendationRatioRequestParams {
    queryOrgCode?: string;
}

export interface GetSuccessionManagementCustomerV1ReviewTalentsFieldValuesRequestParams {
    targetProperty?: string;
    queryOrgCode?: string;
    queryOrgName?: string;
    queryPlaceOfAction?: string;
    queryTalentPoolTitle?: string;
    pagingPage?: number;
    pagingSize?: number;
    queryAreCode?: string;
}

export interface GetSuccessionManagementCustomerV1TalentsFieldValuesRequestParams {
    targetProperty?: string;
    queryOrgCode?: string;
    queryOrgName?: string;
    queryPlaceOfAction?: string;
    pagingPage?: number;
    pagingSize?: number;
    queryTalentPoolTitle?: string;
    queryAreCode?: string;
}

export interface GetSuccessionManagementPartnerV1RatioFieldValuesRequestParams {
    queryTargetProperty?: string;
    queryOrgCode?: string;
    pagingPage?: number;
    pagingSize?: number;
}

export interface GetSuccessionManagementPartnerV1RecommendationRatioRequestParams {
    queryOrgCode?: string;
}

export interface GetSuccessionManagementPartnerV1ReviewTalentsFieldValuesRequestParams {
    targetProperty?: string;
    queryOrgCode?: string;
    queryOrgName?: string;
    queryPlaceOfAction?: string;
    queryTalentPoolTitle?: string;
    queryRecommendationState?: string;
    pagingPage?: number;
    pagingSize?: number;
    queryAreCode?: string;
    queryReviewExcluded?: Array<string>;
    queryIsWildcardCandidate?: boolean;
}

export interface PatchSuccessionManagementCustomerV1ApprovalsRequestParams {
    id: string;
    approvalId: string;
    acknowledgeTalentRecommendation?: AcknowledgeTalentRecommendation;
}

export interface PatchSuccessionManagementPartnerV1ApprovalsRequestParams {
    id: string;
    approvalId: string;
    acknowledgeTalentRecommendation?: AcknowledgeTalentRecommendation;
}

export interface PostSuccessionManagementCustomerV1RecommendationsRequestParams {
    createTalentRecommendation?: CreateTalentRecommendation;
}

export interface PostSuccessionManagementPartnerV1RecommendationsRequestParams {
    createTalentRecommendation?: CreateTalentRecommendation;
}

export interface PutSuccessionManagementPartnerV1TalentRecommendationsIdRequestParams {
    id: string;
    updateTalentRecommendation?: UpdateTalentRecommendation;
}


@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Recommendations - Revert Partner
     * Only callable for HR BP and admins
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId.');
        }
        const approvalId = requestParameters.approvalId;
        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling deleteSuccessionManagementCustomerV1RecommendationsIdApprovalsApprovalId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talent-recommendations/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/approvals/${this.configuration.encodeParam({name: "approvalId", value: approvalId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TalentRecommendation>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendation - Revert
     * Reverts a recommendation. Only callable by HR BP and admins.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId(requestParameters: DeleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId.');
        }
        const approvalId = requestParameters.approvalId;
        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling deleteSuccessionManagementPartnerV1RecommendationsIdApprovalsApprovalId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendations/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/approvals/${this.configuration.encodeParam({name: "approvalId", value: approvalId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TalentRecommendation>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Mass delete recommendation
     * Mass delete talent recommendations
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSuccessionManagementPartnerV1TalentRecommendationsDelete(requestParameters: DeleteSuccessionManagementPartnerV1TalentRecommendationsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteSuccessionManagementPartnerV1TalentRecommendationsDelete(requestParameters: DeleteSuccessionManagementPartnerV1TalentRecommendationsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteSuccessionManagementPartnerV1TalentRecommendationsDelete(requestParameters: DeleteSuccessionManagementPartnerV1TalentRecommendationsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteSuccessionManagementPartnerV1TalentRecommendationsDelete(requestParameters: DeleteSuccessionManagementPartnerV1TalentRecommendationsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryTalentPoolReferenceMetaTypes = requestParameters.queryTalentPoolReferenceMetaTypes;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryGid = requestParameters.queryGid;
        const querySearch = requestParameters.querySearch;
        const queryTalentPoolId = requestParameters.queryTalentPoolId;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgNames = requestParameters.queryOrgNames;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const queryAreCodes = requestParameters.queryAreCodes;
        const deleteTalentRecommendationModel = requestParameters.deleteTalentRecommendationModel;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTalentPoolTitle) {
            queryTalentPoolTitle.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolTitle');
            })
        }
        if (queryTalentPoolReferenceMetaTypes) {
            queryTalentPoolReferenceMetaTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.talentPoolReferenceMetaTypes');
            })
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (querySearch !== undefined && querySearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySearch, 'query.search');
        }
        if (queryTalentPoolId !== undefined && queryTalentPoolId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolId, 'query.talentPoolId');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction) {
            queryPlaceOfAction.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.placeOfAction');
            })
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgNames) {
            queryOrgNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgNames');
            })
        }
        if (queryRecommendationState) {
            queryRecommendationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.recommendationState');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendations/delete`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deleteTalentRecommendationModel,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Ratio KPI Field Values
     * Provides the organizations available for the currently logged in user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1RatioFieldValues(requestParameters: GetSuccessionManagementCustomerV1RatioFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FieldValuesPage>;
    public getSuccessionManagementCustomerV1RatioFieldValues(requestParameters: GetSuccessionManagementCustomerV1RatioFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1RatioFieldValues(requestParameters: GetSuccessionManagementCustomerV1RatioFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1RatioFieldValues(requestParameters: GetSuccessionManagementCustomerV1RatioFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTargetProperty = requestParameters.queryTargetProperty;
        const queryOrgCode = requestParameters.queryOrgCode;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTargetProperty, 'query.TargetProperty');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'Paging.Page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'Paging.Size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talent-recommendation-ratio/field-values`;
        return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Ratio KPI
     * Provides the ratio KPI for recommendations within a given organization
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1RecommendationRatio(requestParameters: GetSuccessionManagementCustomerV1RecommendationRatioRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<RecommendationRatio>;
    public getSuccessionManagementCustomerV1RecommendationRatio(requestParameters: GetSuccessionManagementCustomerV1RecommendationRatioRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<RecommendationRatio>>;
    public getSuccessionManagementCustomerV1RecommendationRatio(requestParameters: GetSuccessionManagementCustomerV1RecommendationRatioRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<RecommendationRatio>>;
    public getSuccessionManagementCustomerV1RecommendationRatio(requestParameters: GetSuccessionManagementCustomerV1RecommendationRatioRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryOrgCode = requestParameters.queryOrgCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talent-recommendation-ratio`;
        return this.httpClient.request<RecommendationRatio>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Get talents to review Field Values
     * Provides list of field values
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FieldValuesPage>;
    public getSuccessionManagementCustomerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1ReviewTalentsFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const targetProperty = requestParameters.targetProperty;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const queryAreCode = requestParameters.queryAreCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetProperty !== undefined && targetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetProperty, 'targetProperty');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.OrgName');
        }
        if (queryPlaceOfAction !== undefined && queryPlaceOfAction !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPlaceOfAction, 'query.PlaceOfAction');
        }
        if (queryTalentPoolTitle !== undefined && queryTalentPoolTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolTitle, 'query.TalentPoolTitle');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'Paging.Page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'Paging.Size');
        }
        if (queryAreCode !== undefined && queryAreCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAreCode, 'query.areCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/review-talents/field-values`;
        return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Get talents Field Values
     * Provides list of field values
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementCustomerV1TalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1TalentsFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FieldValuesPage>;
    public getSuccessionManagementCustomerV1TalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1TalentsFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1TalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1TalentsFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FieldValuesPage>>;
    public getSuccessionManagementCustomerV1TalentsFieldValues(requestParameters: GetSuccessionManagementCustomerV1TalentsFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const targetProperty = requestParameters.targetProperty;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryAreCode = requestParameters.queryAreCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetProperty !== undefined && targetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetProperty, 'targetProperty');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.OrgName');
        }
        if (queryPlaceOfAction !== undefined && queryPlaceOfAction !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPlaceOfAction, 'query.PlaceOfAction');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'Paging.Page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'Paging.Size');
        }
        if (queryTalentPoolTitle !== undefined && queryTalentPoolTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolTitle, 'query.TalentPoolTitle');
        }
        if (queryAreCode !== undefined && queryAreCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAreCode, 'query.areCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talents/field-values`;
        return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Ratio KPI Field Values
     * Provides the organizations available for the currently logged in user. Only callable as admin or HR BP
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1RatioFieldValues(requestParameters: GetSuccessionManagementPartnerV1RatioFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FieldValuesPage>;
    public getSuccessionManagementPartnerV1RatioFieldValues(requestParameters: GetSuccessionManagementPartnerV1RatioFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FieldValuesPage>>;
    public getSuccessionManagementPartnerV1RatioFieldValues(requestParameters: GetSuccessionManagementPartnerV1RatioFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FieldValuesPage>>;
    public getSuccessionManagementPartnerV1RatioFieldValues(requestParameters: GetSuccessionManagementPartnerV1RatioFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTargetProperty = requestParameters.queryTargetProperty;
        const queryOrgCode = requestParameters.queryOrgCode;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTargetProperty, 'query.TargetProperty');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'Paging.Page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'Paging.Size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendation-ratio/field-values`;
        return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Ratio KPI
     * Provides the ratio KPI for recommendations within a given organization. Only callable by HR BP and admins
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1RecommendationRatio(requestParameters: GetSuccessionManagementPartnerV1RecommendationRatioRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<RecommendationRatio>;
    public getSuccessionManagementPartnerV1RecommendationRatio(requestParameters: GetSuccessionManagementPartnerV1RecommendationRatioRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<RecommendationRatio>>;
    public getSuccessionManagementPartnerV1RecommendationRatio(requestParameters: GetSuccessionManagementPartnerV1RecommendationRatioRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<RecommendationRatio>>;
    public getSuccessionManagementPartnerV1RecommendationRatio(requestParameters: GetSuccessionManagementPartnerV1RecommendationRatioRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryOrgCode = requestParameters.queryOrgCode;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.OrgCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendation-ratio`;
        return this.httpClient.request<RecommendationRatio>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Talent Pool - Get talents to review Field Values
     * Provides list of field values
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccessionManagementPartnerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FieldValuesPage>;
    public getSuccessionManagementPartnerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FieldValuesPage>>;
    public getSuccessionManagementPartnerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FieldValuesPage>>;
    public getSuccessionManagementPartnerV1ReviewTalentsFieldValues(requestParameters: GetSuccessionManagementPartnerV1ReviewTalentsFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const targetProperty = requestParameters.targetProperty;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgName = requestParameters.queryOrgName;
        const queryPlaceOfAction = requestParameters.queryPlaceOfAction;
        const queryTalentPoolTitle = requestParameters.queryTalentPoolTitle;
        const queryRecommendationState = requestParameters.queryRecommendationState;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const queryAreCode = requestParameters.queryAreCode;
        const queryReviewExcluded = requestParameters.queryReviewExcluded;
        const queryIsWildcardCandidate = requestParameters.queryIsWildcardCandidate;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetProperty !== undefined && targetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetProperty, 'targetProperty');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgName !== undefined && queryOrgName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgName, 'query.orgName');
        }
        if (queryPlaceOfAction !== undefined && queryPlaceOfAction !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPlaceOfAction, 'query.placeOfAction');
        }
        if (queryTalentPoolTitle !== undefined && queryTalentPoolTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTalentPoolTitle, 'query.talentPoolTitle');
        }
        if (queryRecommendationState !== undefined && queryRecommendationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryRecommendationState, 'query.recommendationState');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'Paging.Page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'Paging.Size');
        }
        if (queryAreCode !== undefined && queryAreCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAreCode, 'query.areCode');
        }
        if (queryReviewExcluded) {
            queryReviewExcluded.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.reviewExcluded');
            })
        }
        if (queryIsWildcardCandidate !== undefined && queryIsWildcardCandidate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryIsWildcardCandidate, 'query.isWildcardCandidate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/review-talents/field-values`;
        return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Acknowledge recommendation
     * Acknowledges (approve/reject) a recommendation. Only callable as N-3, N-2 and N-1 manager.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchSuccessionManagementCustomerV1Approvals(requestParameters: PatchSuccessionManagementCustomerV1ApprovalsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public patchSuccessionManagementCustomerV1Approvals(requestParameters: PatchSuccessionManagementCustomerV1ApprovalsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public patchSuccessionManagementCustomerV1Approvals(requestParameters: PatchSuccessionManagementCustomerV1ApprovalsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public patchSuccessionManagementCustomerV1Approvals(requestParameters: PatchSuccessionManagementCustomerV1ApprovalsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchSuccessionManagementCustomerV1Approvals.');
        }
        const approvalId = requestParameters.approvalId;
        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling patchSuccessionManagementCustomerV1Approvals.');
        }
        const acknowledgeTalentRecommendation = requestParameters.acknowledgeTalentRecommendation;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talent-recommendations/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/approvals/${this.configuration.encodeParam({name: "approvalId", value: approvalId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TalentRecommendation>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: acknowledgeTalentRecommendation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Acknowledge recommendation
     * Acknowledges (approve/reject) a recommendation. Only callable as HR BPs and admins.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchSuccessionManagementPartnerV1Approvals(requestParameters: PatchSuccessionManagementPartnerV1ApprovalsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public patchSuccessionManagementPartnerV1Approvals(requestParameters: PatchSuccessionManagementPartnerV1ApprovalsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public patchSuccessionManagementPartnerV1Approvals(requestParameters: PatchSuccessionManagementPartnerV1ApprovalsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public patchSuccessionManagementPartnerV1Approvals(requestParameters: PatchSuccessionManagementPartnerV1ApprovalsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchSuccessionManagementPartnerV1Approvals.');
        }
        const approvalId = requestParameters.approvalId;
        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling patchSuccessionManagementPartnerV1Approvals.');
        }
        const acknowledgeTalentRecommendation = requestParameters.acknowledgeTalentRecommendation;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendations/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/approvals/${this.configuration.encodeParam({name: "approvalId", value: approvalId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TalentRecommendation>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: acknowledgeTalentRecommendation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations - Create new recommendation
     * Starts the recommendation process for an employee
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSuccessionManagementCustomerV1Recommendations(requestParameters: PostSuccessionManagementCustomerV1RecommendationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public postSuccessionManagementCustomerV1Recommendations(requestParameters: PostSuccessionManagementCustomerV1RecommendationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public postSuccessionManagementCustomerV1Recommendations(requestParameters: PostSuccessionManagementCustomerV1RecommendationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public postSuccessionManagementCustomerV1Recommendations(requestParameters: PostSuccessionManagementCustomerV1RecommendationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const createTalentRecommendation = requestParameters.createTalentRecommendation;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/customer/v1/talent-recommendations`;
        return this.httpClient.request<TalentRecommendation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createTalentRecommendation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations (HR BP) - Create new recommendation
     * Starts the recommendation process for an employee
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSuccessionManagementPartnerV1Recommendations(requestParameters: PostSuccessionManagementPartnerV1RecommendationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public postSuccessionManagementPartnerV1Recommendations(requestParameters: PostSuccessionManagementPartnerV1RecommendationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public postSuccessionManagementPartnerV1Recommendations(requestParameters: PostSuccessionManagementPartnerV1RecommendationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public postSuccessionManagementPartnerV1Recommendations(requestParameters: PostSuccessionManagementPartnerV1RecommendationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const createTalentRecommendation = requestParameters.createTalentRecommendation;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendations`;
        return this.httpClient.request<TalentRecommendation>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createTalentRecommendation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommendations (HR BP) - Update recommendation talent pool
     * Changes the talent pool assignment of a recommendation without restarting approval chain. Only callable by HR BPs and admins
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putSuccessionManagementPartnerV1TalentRecommendationsId(requestParameters: PutSuccessionManagementPartnerV1TalentRecommendationsIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TalentRecommendation>;
    public putSuccessionManagementPartnerV1TalentRecommendationsId(requestParameters: PutSuccessionManagementPartnerV1TalentRecommendationsIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TalentRecommendation>>;
    public putSuccessionManagementPartnerV1TalentRecommendationsId(requestParameters: PutSuccessionManagementPartnerV1TalentRecommendationsIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TalentRecommendation>>;
    public putSuccessionManagementPartnerV1TalentRecommendationsId(requestParameters: PutSuccessionManagementPartnerV1TalentRecommendationsIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putSuccessionManagementPartnerV1TalentRecommendationsId.');
        }
        const updateTalentRecommendation = requestParameters.updateTalentRecommendation;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/succession-management/partner/v1/talent-recommendations/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TalentRecommendation>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTalentRecommendation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
