<div>
  @if (contentEdit && item) {
    <coin-v2-checkbox class="spacing-elements" [(ngModel)]="item.fullWidth" [label]="'cms.emails.full-width' | translate"> </coin-v2-checkbox>
    @if (showBackgroundPicker) {
      <div class="spacing-elements">
        <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
      </div>
    }
    <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  }

  @if (item) {
    <div class="email-btn-container">
      @if (contentEdit) {
        <coin-v2-text-input [label]="'cms.button-text' | translate" [(ngModel)]="item.content.text" />
      }
      @if (contentEdit) {
        <coin-v2-text-input [label]="'cms.button-link-url' | translate" [(ngModel)]="item.content.link" [placeholder]="'cms.button-link-url-placeholder' | translate" />
      }
      @if (!contentEdit) {
        <a [href]="item.content.link" class="email-btn-link" [ngClass]="{ 'btn-full-width': item.fullWidth }">{{ item.content.text }} <span>&#9002;</span></a>
      }
    </div>
  }
</div>
