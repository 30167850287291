@if (item) {
  <div class="cms-chapter-container">
    @if (contentEdit) {
      <coin-v2-text-input [label]="'cms.content-pages.chapter-icon' | translate" [(ngModel)]="item.content.icon" [placeholder]="'cms.content-pages.chapter-icon' | translate" />
    }
    @if (contentEdit) {
      <coin-v2-text-input [label]="'cms.content-pages.chapter-text' | translate" [(ngModel)]="item.content.text" [placeholder]="'cms.content-pages.chapter-text' | translate" />
    }
    <div class="cms-chapter-checks">
      @if (contentEdit) {
        <coin-v2-checkbox [(ngModel)]="item.content['showPrintButton']" [label]="'cms.content-pages.show-print-page-button' | translate" />
      }
      @if (contentEdit) {
        <coin-v2-checkbox [(ngModel)]="item.content['showGermanyRowButton']" [label]="'cms.content-pages.show-germany-row-button' | translate" />
      }
      @if (contentEdit) {
        <coin-v2-checkbox [(ngModel)]="item.requiresAuthentication" [label]="'cms.content-pages.requires-authentication' | translate"> </coin-v2-checkbox>
      }
    </div>
  </div>
}

@if (item) {
  <div class="cms-chapter-main" [id]="item?.id ?? ''">
    <div>
      <div class="chapter-icon">
        <span class="material-icons-outlined">{{ item?.content.icon || 'view_in_ar' }}</span>
      </div>
      <p class="cms-chapter-text">{{ item?.content.text }}</p>
    </div>
    <div>
      @if (item.content['showGermanyRowButton']) {
        <div class="cp-slide-toggle">
          <p>{{ 'general.germany' | translate }}</p>
          <mat-slide-toggle [checked]="germanyRowSlideToggle" (change)="slideChange.emit($event)"></mat-slide-toggle>
          <p>ROW</p>
        </div>
      }
      @if (item.content['showPrintButton']) {
        <coin-action-button (click)="printPage()" [height]="20" class="secondary cms-chapter-print" svgIcon="print" [noicon]="true">{{
          'general.print' | translate
        }}</coin-action-button>
      }
    </div>
  </div>
}
