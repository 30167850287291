import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpHelpersService, LoadingService, TransactionCalculationOperations } from '@coin/shared/data-access';
import { ListViewTagFilterParameter, PaginationState, TransactionStatus } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { merge, Observable, switchMap, tap } from 'rxjs';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import {
  ExportTransactionStatus,
  GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams,
  IncentiveEmployee,
  IncentivePartnerService,
  IncentivePlan,
  PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams,
  PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams,
  Reassignment,
  ReassignmentService,
  TransactionStatusMassOperation,
  TransactionStatusMassOperationMetadata,
  UpdateIncentiveSnapshot,
  UpdateReassignment
} from '../generated';
import { QueryParams } from '../models/query-params.model';
import { ExtendedReassignment } from '../models/selectable-reassignment.model';

import { IncentiveSupportComponentState } from './incentive-support.component.state';
import { ReassignmentQuickFilter } from '../enums/reassignment-quick-filter.enum';
import { ReassignmentState } from '../enums/reassignment-state.enum';

interface ReassignmentStateModel {
  queryParams: QueryParams;
  reassignments: ExtendedReassignment[];
  employeeData: { [key: string]: IncentiveEmployee };
  paginationState: PaginationState;
  filterParameter: ListViewTagFilterParameter[];
  isMultiselectActive: boolean;
}

const initialState = (): ReassignmentStateModel => ({
  queryParams: {},
  reassignments: [],
  employeeData: {},
  paginationState: {
    pageSize: 25,
    nextPage: 1,
    hasMore: true
  },
  filterParameter: [{ category: ReassignmentQuickFilter.State, value: ReassignmentState.Created }],
  isMultiselectActive: false
});

@Injectable()
export class ReassignmentsComponentState extends ImmerComponentStore<ReassignmentStateModel> {
  public reassignments$ = this.select(({ reassignments }) => reassignments);
  public selectedReassignment$ = this.select(({ reassignments }) => reassignments.filter(reassignment => reassignment.isSelected));
  public initialPageLoaded$ = this.select(({ paginationState }) => paginationState.nextPage >= initialState().paginationState.nextPage + 1);
  public filterParameter$ = this.select(({ filterParameter }) => filterParameter);
  public isMultiselectActive$ = this.select(({ isMultiselectActive }) => isMultiselectActive);
  public totalReassignmentsCount$ = this.select(({ paginationState }) => paginationState.total);
  public isOnlyCreatedFilterActive$ = this.select(
    ({ filterParameter }) =>
      filterParameter.length === 1 && filterParameter.find(parameter => parameter.category === ReassignmentQuickFilter.State)?.value === ReassignmentState.Created
  );
  public reassignmentById$ = (id: string) => this.select(({ reassignments }) => reassignments.find(reassignment => reassignment.id === id));
  public employeeDataByReassignmentId$ = (id: string) => this.select(({ employeeData }) => employeeData?.[id]);

  constructor(
    private reassignmentService: ReassignmentService,
    private incentivePartnerService: IncentivePartnerService,
    private destroyRef: DestroyRef,
    private loadingService: LoadingService,
    private httpHelperService: HttpHelpersService,
    private incentiveSupportState: IncentiveSupportComponentState,
    private store: Store
  ) {
    super(initialState());
    merge(this.incentiveSupportState.sortingChange$, this.incentiveSupportState.setFilter$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.resetPaginationState();
        this.loadNextPage();
      });
    this.incentiveSupportState.setSearchItem$.pipe(takeUntilDestroyed()).subscribe(reassignment => {
      this.unshift(reassignment);
    });
  }

  public loadEmployeeData(reassignmentId: string): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    if (selectedSeason) {
      this.reassignmentService
        .getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee({ seasonId: selectedSeason.id, id: reassignmentId })
        .pipe(
          this.loadingService.withLoadingScreen,
          this.httpHelperService.withStatusMessages({ error: 'incentive-support.http-error-messages.loading-employee-data-failed' }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(data =>
          this.setState(state => {
            state.employeeData[reassignmentId] = data;
          })
        );
    }
  }

  public loadNextPage(): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const { paginationState } = this.get();
    const { nextPage, pageSize, hasMore } = paginationState;

    if (hasMore) {
      const selectedSortOption = this.store.selectSnapshot(FilterSortState.selectedSortOption);

      const requestParams: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams = {
        seasonId: selectedSeason.id,
        pagingPage: nextPage,
        pagingSize: pageSize,
        sortingOrderBy: selectedSortOption?.value,
        sortingProperty: selectedSortOption?.key
      };

      const requestParamsWithFilters = this.addSelectedFilters(this.addSelectedQuickFilters(requestParams));

      this.reassignmentService
        .getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments(requestParamsWithFilters)
        .pipe(
          this.loadingService.withLoadingScreen,
          this.httpHelperService.withStatusMessages({ error: 'incentive-support.http-error-messages.loading-reassignments-failed' }),
          tap(reassignmentPage => {
            this.setState(state => {
              const { content, ...paginationState } = reassignmentPage;
              state.paginationState = paginationState;
              state.reassignments = [...state.reassignments, ...content];
            });
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  public updateReassignment(id: string, updateDto: UpdateReassignment, newPlan: IncentivePlan) {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    this.reassignmentService
      .putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId({
        id,
        seasonId: selectedSeason.id,
        updateReassignment: updateDto
      })
      .pipe(this.httpHelperService.withStatusMessages({ success: 'incentive-support.http-success-messages.updated-reassignment' }), takeUntilDestroyed(this.destroyRef))
      .subscribe(updatedReassignment => {
        this.setState(state => {
          const index = state.reassignments.findIndex(reassignment => reassignment.id === updatedReassignment.id);
          state.reassignments[index] = { ...state.reassignments[index], ...updatedReassignment, newPlan };
        });
      });
  }

  public updateSnapshotData(reassignmentId: string, recordId: string, snapshotId: string, updateIncentiveSnapshot: UpdateIncentiveSnapshot) {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    this.incentivePartnerService
      .patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId({
        recordId,
        snapshotId,
        seasonId: selectedSeason.id,
        updateIncentiveSnapshot
      })
      .pipe(
        this.httpHelperService.withStatusMessages({ success: 'incentive-support.http-success-messages.updated-snapshotdata' }),
        this.loadingService.withLoadingScreen,
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(updatedSnapshot => {
        this.setState(state => {
          const index = state.reassignments.findIndex(reassignment => reassignment.id === reassignmentId);
          state.reassignments[index] = { ...state.reassignments[index], newRecord: { ...state.reassignments[index].newRecord, initialSnapshot: updatedSnapshot } };
        });
      });
  }

  public unshift(reassignment: Reassignment): void {
    const { reassignments } = this.get();
    const index = reassignments.findIndex(existingReassignment => existingReassignment.id === reassignment);
    this.setState(state => {
      if (index > -1) {
        state.reassignments.splice(index, 1);
      }
      state.reassignments.unshift(reassignment);
    });
  }

  private addSelectedQuickFilters(
    requestParams: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams
  ): GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams {
    const { filterParameter } = this.get();
    const stateFilter = filterParameter.find(parameter => parameter.category === ReassignmentQuickFilter.State);
    const reasonFilters = filterParameter.filter(parameter => parameter.category === ReassignmentQuickFilter.Reason);
    return {
      ...requestParams,
      queryState: stateFilter?.value ?? ReassignmentState.Created,
      queryReason: reasonFilters.map(reasonFilter => reasonFilter.value)
    };
  }

  private addSelectedFilters(
    requestParams: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams
  ): GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams {
    const selectedFilters = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
    const returnParams = { ...requestParams };
    for (const selectedFilter of selectedFilters) {
      const isStartsWithOrContainsFilter = /StartsWith|Contains/.test(selectedFilter.key);
      const apiFilterKeyPrefix = 'query';
      const apiFilterKey = `${apiFilterKeyPrefix}${selectedFilter.key.replace(/StartsWith|Contains/, '')}`;
      if (selectedFilter.values) {
        returnParams[apiFilterKey] = isStartsWithOrContainsFilter ? selectedFilter.values[0] : selectedFilter.values;
      }
    }
    return returnParams;
  }

  public addFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter.push(parameter);
    });
  }

  public removeFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = state.filterParameter.filter(existingFilterParameter => existingFilterParameter.value !== parameter.value);
    });
  }

  public restFilterParameters(): void {
    this.setState(state => {
      state.filterParameter = initialState().filterParameter;
    });
  }

  public toggleMultiselect(): void {
    this.setState(state => {
      state.isMultiselectActive = !state.isMultiselectActive;
    });
  }

  public unselectAll(): void {
    this.setState(state => {
      state.reassignments = state.reassignments.map(reassignment => ({ ...reassignment, isSelected: false }));
    });
  }

  public toggleSelection(index: number): void {
    this.setState(state => {
      state.reassignments[index].isSelected = !state.reassignments[index]?.isSelected;
    });
  }

  public acceptReassignmentById(id: string): void {
    const { reassignments } = this.get();
    this.acceptReassignment(reassignments.findIndex(reassignment => reassignment.id === id));
  }

  public acceptReassignment(index: number): void {
    if (index < 0) {
      throw new Error('Index must be >= 0');
    }
    const { reassignments, filterParameter } = this.get();
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const reassignment = reassignments[index];

    this.reassignmentService
      .putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept({
        id: reassignment.id,
        seasonId: selectedSeason.id,
        acceptReassignment: {
          id: reassignment.id,
          employeeId: reassignment.record?.initialSnapshot?.employeeId ?? reassignment.newRecord?.initialSnapshot?.employeeId
        }
      })
      .pipe(
        this.loadingService.withLoadingScreen,
        this.httpHelperService.withStatusMessages({
          error: 'incentive-support.http-error-messages.accepting-reassignment-failed',
          success: 'incentive-support.http-success-messages.accepted-reassignment'
        }),
        tap(updatedReassignment => {
          const isAcceptFilterActive = filterParameter.find(filter => filter.category === ReassignmentQuickFilter.State && filter.value === ReassignmentState.Accepted);
          this.setState(state => {
            state.reassignments[index] = { ...state.reassignments[index], ...updatedReassignment, isHidden: !isAcceptFilterActive };
            state.paginationState.total = isAcceptFilterActive ? state.paginationState.total : state.paginationState.total - 1;
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public rejectReassignmentById(id: string): void {
    const { reassignments } = this.get();
    this.rejectReassignment(reassignments.findIndex(reassignment => reassignment.id === id));
  }

  public rejectReassignment(index: number): void {
    if (index < 0) {
      throw new Error('Index must be >= 0');
    }
    const { reassignments, filterParameter } = this.get();
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const reassignment = reassignments[index];
    this.reassignmentService
      .putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject({
        id: reassignment.id,
        seasonId: selectedSeason.id
      })
      .pipe(
        this.loadingService.withLoadingScreen,
        this.httpHelperService.withStatusMessages({
          error: 'incentive-support.http-error-messages.rejecting-reassignment-failed',
          success: 'incentive-support.http-success-messages.rejected-reassignment'
        }),
        tap(updatedReassignment => {
          const isRejectFilterActive = filterParameter.find(filter => filter.category === ReassignmentQuickFilter.State && filter.value === ReassignmentState.Rejected);
          this.setState(state => {
            state.reassignments[index] = { ...state.reassignments[index], ...updatedReassignment, isHidden: !isRejectFilterActive };
            state.paginationState.total = isRejectFilterActive ? state.paginationState.total : state.paginationState.total - 1;
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public massAcceptReassignments(transactionCalculationOperations: TransactionCalculationOperations): Observable<TransactionStatusMassOperationMetadata> {
    const requestParams = this.getMassOperationRequestParams('acceptReassignments');
    return this.runMassOperation(this.reassignmentService.putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept(requestParams), transactionCalculationOperations);
  }

  public massRejectReassignments(transactionCalculationOperations: TransactionCalculationOperations): Observable<TransactionStatusMassOperationMetadata> {
    const requestParams = this.getMassOperationRequestParams('rejectReassignments');
    return this.runMassOperation(this.reassignmentService.putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject(requestParams), transactionCalculationOperations);
  }

  private getMassOperationRequestParams(
    idArrayPropertyKey: 'acceptReassignments' | 'rejectReassignments'
  ): PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams | PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams {
    const { reassignments } = this.get();
    const selectedReassignments = reassignments.filter(reassignment => reassignment.isSelected);

    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    let requestParams: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams | PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams = {
      seasonId: selectedSeason.id
    };

    if (selectedReassignments.length > 0) {
      requestParams[idArrayPropertyKey] = { reassignmentIds: selectedReassignments.map(reassignment => reassignment.id) };
    } else {
      requestParams = this.addSelectedFilters(this.addSelectedQuickFilters(requestParams));
    }

    return requestParams;
  }

  private runMassOperation(
    operation$: Observable<TransactionStatusMassOperationMetadata>,
    transactionCalculationOperations: TransactionCalculationOperations
  ): Observable<TransactionStatusMassOperationMetadata> {
    return operation$.pipe(
      this.loadingService.withLoadingScreen,
      this.httpHelperService.withStatusMessages({
        error: 'incentive-support.http-error-messages.starting-mass-operation-failed',
        success: 'incentive-support.http-success-messages.started-mass-operation'
      }),
      switchMap((transaction: TransactionStatusMassOperation) => transactionCalculationOperations.checkTransactionStatus(transaction.transactionId)),
      tap(() => {
        this.resetPaginationState();
        this.toggleMultiselect();
        this.loadNextPage();
      })
    );
  }

  public exportExcel(transactionCalculationOperations: TransactionCalculationOperations) {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const requestParams = this.addSelectedFilters(this.addSelectedQuickFilters({ seasonId: selectedSeason.id }));

    return this.reassignmentService.putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport(requestParams).pipe(
      this.loadingService.withLoadingScreen,
      this.httpHelperService.withStatusMessages({
        error: 'incentive-support.http-error-messages.starting-export-failed',
        success: 'incentive-support.http-success-messages.started-export'
      }),
      o$ => transactionCalculationOperations.startProcess<ExportTransactionStatus>(o$ as unknown as Observable<TransactionStatus>, true, false, true, false)
    );
  }

  public resetPaginationState(): void {
    this.setState(state => {
      state.paginationState = initialState().paginationState;
      state.reassignments = [];
    });
  }
}
