/**
 * RestructuringCustomerAPI
 * API for Restructuring customer module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: tim.weiss@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RestructuringUserModel } from './restructuring-user-model.model';
import { RestructuringRequestResultModel } from './restructuring-request-result-model.model';
import { RestructuringEmployeeSnapshotLightModel } from './restructuring-employee-snapshot-light-model.model';


export interface RestructuringRequestModel { 
    id?: string;
    employeeSnapshotId?: string;
    employeeId?: string;
    seasonId?: string;
    seasonTitle?: string | null;
    planId?: string;
    planTitle?: string | null;
    employeeSnapshot?: RestructuringEmployeeSnapshotLightModel;
    createdBy?: RestructuringUserModel;
    updatedBy?: RestructuringUserModel;
    createdAt?: string | null;
    updatedAt?: string | null;
    state?: RestructuringRequestModel.StateEnum | null;
    fiscalYear?: string | null;
    isFrozen?: boolean;
    result?: Array<RestructuringRequestResultModel> | null;
    reason?: string | null;
}
export namespace RestructuringRequestModel {
    export type StateEnum = 'Draft' | 'Monitoring' | 'Accepted' | 'Rejected';
    export const StateEnum = {
        Draft: 'Draft' as StateEnum,
        Monitoring: 'Monitoring' as StateEnum,
        Accepted: 'Accepted' as StateEnum,
        Rejected: 'Rejected' as StateEnum
    };
}


