/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  RestructuringCalculationCalculateRestructuringModel,
  RestructuringCalculationRestructuringCalculationModel,
  RestructuringCalculationSaveRestructuringCalculationModel,
  RestructuringCommentsCreateRestructuringRequestCommentModel,
  RestructuringCommentsRestructuringCommentModel,
  RestructuringReportingCreateRestructuringCommitmentExportModel,
  RestructuringReportingCreateRestructuringReportingExportModel,
  RestructuringReportingCreateRestructuringRequestReportModel,
  RestructuringReportingRestructuringReportingHeaderModel,
  RestructuringReportingRestructuringRequestReportModel,
  RestructuringRequestsCheckPermissionsForRestructuringDownloadModel,
  RestructuringRequestsRestructuringDashboardModel,
  RestructuringRequestsRestructuringRequestModel,
  RestructuringRequestsUpdateRestructuringRequestModel,
  RestructuringSeasonsRestructuringSeasonLightModel,
  SharedPageModelOfActivityStreamsActivityStreamModelOfSystemString,
  SharedPageModelOfRestructuringCommentsRestructuringCommentModel,
  SharedPageModelOfRestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel,
  SharedPageModelOfRestructuringReportingRestructuringRequestReportModel,
  SharedPageModelOfRestructuringRequestsRestructuringRequestModel,
  SharedPageModelOfSharedFieldItemModel,
  SharedPageModelOfSystemString,
  TransactionStatusOfExportStatusMetadata,
  TransactionStatusOfLetterBatchMetadata
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface RestructuringCustomerServiceCalculateRestructuringCalculationRequestParams {
  restructuringCalculationCalculateRestructuringModel?: RestructuringCalculationCalculateRestructuringModel;
}

export interface RestructuringCustomerServiceCheckPermissionsRestructuringRequestRequestParams {
  restructuringRequestsCheckPermissionsForRestructuringDownloadModel?: RestructuringRequestsCheckPermissionsForRestructuringDownloadModel;
}

export interface RestructuringCustomerServiceCreateCalculationFileRestructuringRequestRequestParams {
  requestId: string;
}

export interface RestructuringCustomerServiceCreateCommentRestructuringRequestRequestParams {
  requestId: string;
  restructuringCommentsCreateRestructuringRequestCommentModel?: RestructuringCommentsCreateRestructuringRequestCommentModel;
}

export interface RestructuringCustomerServiceCreateExportRestructuringCommitmentRequestParams {
  restructuringReportingCreateRestructuringCommitmentExportModel?: RestructuringReportingCreateRestructuringCommitmentExportModel;
}

export interface RestructuringCustomerServiceCreateExportRestructuringReportingRequestParams {
  restructuringReportingCreateRestructuringReportingExportModel?: RestructuringReportingCreateRestructuringReportingExportModel;
}

export interface RestructuringCustomerServiceCreateLetterRestructuringRequestRequestParams {
  requestId: string;
}

export interface RestructuringCustomerServiceCreateReportItemRestructuringCommitmentRequestParams {
  requestId: string;
  restructuringReportingCreateRestructuringRequestReportModel?: RestructuringReportingCreateRestructuringRequestReportModel;
}

export interface RestructuringCustomerServiceCreateReportItemRestructuringReportingRequestParams {
  requestId: string;
  restructuringReportingCreateRestructuringRequestReportModel?: RestructuringReportingCreateRestructuringRequestReportModel;
}

export interface RestructuringCustomerServiceDeleteCommentRestructuringRequestRequestParams {
  commentId: string;
  requestId: string;
}

export interface RestructuringCustomerServiceDeleteRestructuringRequestRequestParams {
  requestId: string;
}

export interface RestructuringCustomerServiceGetActiveSeasonsEmployeeSnapshotsRequestParams {
  employeeId: string;
  requestId?: string;
}

export interface RestructuringCustomerServiceGetActivityStreamForRequestRestructuringRequestRequestParams {
  requestId: string;
  page?: number;
  size?: number;
}

export interface RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringCommitmentRequestParams {
  countryCode: string;
  assignmentId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringReportingRequestParams {
  countryCode: string;
  assignmentId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringCommitmentRequestParams {
  seasonId: string;
  assignmentId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringReportingRequestParams {
  seasonId: string;
  assignmentId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetCalculationByRequestRestructuringRequestRequestParams {
  requestId: string;
}

export interface RestructuringCustomerServiceGetDashboardRestructuringRequestRequestParams {
  searchString?: string;
  fiscalYear?: string;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  querySeasonId?: Array<string>;
  queryPlanId?: Array<string>;
  queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryLineManagerGid?: Array<string>;
  queryOrgCode?: Array<string>;
  queryOrgCodeStartsWith?: Array<string>;
  queryFiscalYear?: Array<string>;
  queryCompanyManagerGid?: Array<string>;
  queryCreatedByGid?: Array<string>;
}

export interface RestructuringCustomerServiceGetFieldValuesByCountryRestructuringCommitmentRequestParams {
  countryCode: string;
  fieldName: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetFieldValuesByCountryRestructuringReportingRequestParams {
  countryCode: string;
  fieldName: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringCommitmentRequestParams {
  seasonId: string;
  fieldName: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringReportingRequestParams {
  seasonId: string;
  fieldName: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetHeadersRestructuringCommitmentRequestParams {
  countryCode: string;
}

export interface RestructuringCustomerServiceGetHeadersRestructuringReportingRequestParams {
  countryCode: string;
}

export interface RestructuringCustomerServiceGetItemsByCountryRestructuringCommitmentRequestParams {
  countryCode: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetItemsByCountryRestructuringReportingRequestParams {
  countryCode: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetItemsByRequestRestructuringCommitmentRequestParams {
  requestId: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface RestructuringCustomerServiceGetItemsByRequestRestructuringReportingRequestParams {
  requestId: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface RestructuringCustomerServiceGetItemsBySeasonRestructuringCommitmentRequestParams {
  seasonId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetItemsBySeasonRestructuringReportingRequestParams {
  seasonId: string;
  searchString?: string;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
  queryAttributes?: { [key: string]: string };
  queryAttributeByName?: { [key: string]: string };
  queryAssignments?: { [key: string]: string };
  queryGid?: string;
  queryFirstName?: string;
  queryLastName?: string;
  queryFullName?: string;
  queryOrgCodeStartsWith?: string;
  queryContractStatus?: Array<'Terminated' | 'Dormant' | 'Active' | 'Other'>;
  queryRequestState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryFiscalYear?: Array<string>;
  queryAre?: Array<string>;
  queryCountry?: Array<string>;
  queryOrgCode?: Array<string>;
  queryPlanId?: Array<string>;
  queryReportingDate?: string;
  queryUpdatedBy?: string;
  querySeasonId?: Array<string>;
  querySeasonTitle?: Array<string>;
  queryPlanTitle?: Array<string>;
  querySubState?: Array<string>;
}

export interface RestructuringCustomerServiceGetRequestRestructuringRequestRequestParams {
  requestId: string;
}

export interface RestructuringCustomerServiceQueryAttributesRequestParams {
  id: string;
}

export interface RestructuringCustomerServiceQueryCommentsRestructuringRequestRequestParams {
  requestId: string;
  page?: number;
  size?: number;
}

export interface RestructuringCustomerServiceQueryEmployeeSnapshotsRequestParams {
  searchString?: string;
  includeManagerInformation?: boolean;
  includeAttributes?: boolean;
  includeLetter?: boolean;
  queryCountry?: Array<string>;
  queryLegalEntity?: Array<string>;
  queryContractStatus?: Array<string>;
  queryGid?: string;
  queryFullName?: string;
  queryOrgCode?: string;
  queryAttributes?: { [key: string]: string };
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface RestructuringCustomerServiceQueryFieldValueRestructuringRequestRequestParams {
  fieldName: string;
  searchString?: string;
  fiscalYear?: string;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  querySeasonId?: Array<string>;
  queryPlanId?: Array<string>;
  queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryLineManagerGid?: Array<string>;
  queryOrgCode?: Array<string>;
  queryOrgCodeStartsWith?: Array<string>;
  queryFiscalYear?: Array<string>;
  queryCompanyManagerGid?: Array<string>;
  queryCreatedByGid?: Array<string>;
}

export interface RestructuringCustomerServiceQueryFieldValueWithIdRestructuringRequestRequestParams {
  fieldName: string;
  searchString?: string;
  fiscalYear?: string;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  querySeasonId?: Array<string>;
  queryPlanId?: Array<string>;
  queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryLineManagerGid?: Array<string>;
  queryOrgCode?: Array<string>;
  queryOrgCodeStartsWith?: Array<string>;
  queryFiscalYear?: Array<string>;
  queryCompanyManagerGid?: Array<string>;
  queryCreatedByGid?: Array<string>;
}

export interface RestructuringCustomerServiceQueryRestructuringRequestRequestParams {
  searchString?: string;
  fiscalYear?: string;
  queryCountry?: Array<string>;
  queryAre?: Array<string>;
  querySeasonId?: Array<string>;
  queryPlanId?: Array<string>;
  queryState?: Array<'Draft' | 'Monitoring' | 'Accepted' | 'Rejected' | 'Published'>;
  queryLineManagerGid?: Array<string>;
  queryOrgCode?: Array<string>;
  queryOrgCodeStartsWith?: Array<string>;
  queryFiscalYear?: Array<string>;
  queryCompanyManagerGid?: Array<string>;
  queryCreatedByGid?: Array<string>;
  pageablePage?: number;
  pageableSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface RestructuringCustomerServiceSaveCalculationRestructuringCalculationRequestParams {
  restructuringCalculationSaveRestructuringCalculationModel?: RestructuringCalculationSaveRestructuringCalculationModel;
}

export interface RestructuringCustomerServiceUpdateRequestRestructuringRequestRequestParams {
  restructuringRequestsUpdateRestructuringRequestModel?: RestructuringRequestsUpdateRestructuringRequestModel;
}

@Injectable({
  providedIn: 'root'
})
export class RestructuringCustomerService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public calculateRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceCalculateRestructuringCalculationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringCalculationRestructuringCalculationModel>;
  public calculateRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceCalculateRestructuringCalculationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringCalculationRestructuringCalculationModel>>;
  public calculateRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceCalculateRestructuringCalculationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringCalculationRestructuringCalculationModel>>;
  public calculateRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceCalculateRestructuringCalculationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringCalculationCalculateRestructuringModel = requestParameters?.restructuringCalculationCalculateRestructuringModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/calculation/simulate`;
    return this.httpClient.request<RestructuringCalculationRestructuringCalculationModel>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringCalculationCalculateRestructuringModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkPermissionsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCheckPermissionsRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<string>>;
  public checkPermissionsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCheckPermissionsRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<string>>>;
  public checkPermissionsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCheckPermissionsRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<string>>>;
  public checkPermissionsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCheckPermissionsRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringRequestsCheckPermissionsForRestructuringDownloadModel = requestParameters?.restructuringRequestsCheckPermissionsForRestructuringDownloadModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/check-permissions`;
    return this.httpClient.request<Array<string>>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringRequestsCheckPermissionsForRestructuringDownloadModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCalculationFileRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCalculationFileRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public createCalculationFileRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCalculationFileRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public createCalculationFileRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCalculationFileRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public createCalculationFileRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCalculationFileRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling createCalculationFileRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/calculation-file`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCommentRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringCommentsRestructuringCommentModel>;
  public createCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCommentRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringCommentsRestructuringCommentModel>>;
  public createCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCommentRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringCommentsRestructuringCommentModel>>;
  public createCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateCommentRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling createCommentRestructuringRequest.');
    }
    const restructuringCommentsCreateRestructuringRequestCommentModel = requestParameters?.restructuringCommentsCreateRestructuringRequestCommentModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/comments`;
    return this.httpClient.request<RestructuringCommentsRestructuringCommentModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringCommentsCreateRestructuringRequestCommentModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createExportRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public createExportRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public createExportRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public createExportRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringReportingCreateRestructuringCommitmentExportModel = requestParameters?.restructuringReportingCreateRestructuringCommitmentExportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/export`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringReportingCreateRestructuringCommitmentExportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createExportRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public createExportRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public createExportRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public createExportRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateExportRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringReportingCreateRestructuringReportingExportModel = requestParameters?.restructuringReportingCreateRestructuringReportingExportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/export`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringReportingCreateRestructuringReportingExportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createLetterRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateLetterRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public createLetterRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateLetterRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public createLetterRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateLetterRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public createLetterRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceCreateLetterRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling createLetterRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/letter`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createReportItemRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringReportingRestructuringRequestReportModel>;
  public createReportItemRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringReportingRestructuringRequestReportModel>>;
  public createReportItemRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringReportingRestructuringRequestReportModel>>;
  public createReportItemRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling createReportItemRestructuringCommitment.');
    }
    const restructuringReportingCreateRestructuringRequestReportModel = requestParameters?.restructuringReportingCreateRestructuringRequestReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<RestructuringReportingRestructuringRequestReportModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringReportingCreateRestructuringRequestReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createReportItemRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringReportingRestructuringRequestReportModel>;
  public createReportItemRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringReportingRestructuringRequestReportModel>>;
  public createReportItemRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringReportingRestructuringRequestReportModel>>;
  public createReportItemRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceCreateReportItemRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling createReportItemRestructuringReporting.');
    }
    const restructuringReportingCreateRestructuringRequestReportModel = requestParameters?.restructuringReportingCreateRestructuringRequestReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<RestructuringReportingRestructuringRequestReportModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringReportingCreateRestructuringRequestReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteCommentRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteCommentRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteCommentRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteCommentRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteCommentRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const commentId = requestParameters?.commentId;
    if (commentId === null || commentId === undefined) {
      throw new Error('Required parameter commentId was null or undefined when calling deleteCommentRestructuringRequest.');
    }
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling deleteCommentRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/comments/${this.configuration.encodeParam({ name: 'commentId', value: commentId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<boolean>;
  public deleteRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<boolean>>;
  public deleteRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<boolean>>;
  public deleteRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceDeleteRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling deleteRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<boolean>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActiveSeasonsEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceGetActiveSeasonsEmployeeSnapshotsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<RestructuringSeasonsRestructuringSeasonLightModel>>;
  public getActiveSeasonsEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceGetActiveSeasonsEmployeeSnapshotsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<RestructuringSeasonsRestructuringSeasonLightModel>>>;
  public getActiveSeasonsEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceGetActiveSeasonsEmployeeSnapshotsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<RestructuringSeasonsRestructuringSeasonLightModel>>>;
  public getActiveSeasonsEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceGetActiveSeasonsEmployeeSnapshotsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const employeeId = requestParameters?.employeeId;
    if (employeeId === null || employeeId === undefined) {
      throw new Error('Required parameter employeeId was null or undefined when calling getActiveSeasonsEmployeeSnapshots.');
    }
    const requestId = requestParameters?.requestId;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (requestId !== undefined && requestId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>requestId, 'requestId');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/employees/${this.configuration.encodeParam({ name: 'employeeId', value: employeeId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/seasons`;
    return this.httpClient.request<Array<RestructuringSeasonsRestructuringSeasonLightModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActivityStreamForRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetActivityStreamForRequestRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfActivityStreamsActivityStreamModelOfSystemString>;
  public getActivityStreamForRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetActivityStreamForRequestRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfActivityStreamsActivityStreamModelOfSystemString>>;
  public getActivityStreamForRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetActivityStreamForRequestRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfActivityStreamsActivityStreamModelOfSystemString>>;
  public getActivityStreamForRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetActivityStreamForRequestRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling getActivityStreamForRequestRestructuringRequest.');
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/activity-stream`;
    return this.httpClient.request<SharedPageModelOfActivityStreamsActivityStreamModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAssignmentFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getAssignmentFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getAssignmentFieldValuesByCountryRestructuringCommitment.');
    }
    const assignmentId = requestParameters?.assignmentId;
    if (assignmentId === null || assignmentId === undefined) {
      throw new Error('Required parameter assignmentId was null or undefined when calling getAssignmentFieldValuesByCountryRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/assignment-field-values/${this.configuration.encodeParam({ name: 'assignmentId', value: assignmentId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAssignmentFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getAssignmentFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesByCountryRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getAssignmentFieldValuesByCountryRestructuringReporting.');
    }
    const assignmentId = requestParameters?.assignmentId;
    if (assignmentId === null || assignmentId === undefined) {
      throw new Error('Required parameter assignmentId was null or undefined when calling getAssignmentFieldValuesByCountryRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/assignment-field-values/${this.configuration.encodeParam({ name: 'assignmentId', value: assignmentId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAssignmentFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getAssignmentFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getAssignmentFieldValuesBySeasonRestructuringCommitment.');
    }
    const assignmentId = requestParameters?.assignmentId;
    if (assignmentId === null || assignmentId === undefined) {
      throw new Error('Required parameter assignmentId was null or undefined when calling getAssignmentFieldValuesBySeasonRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/assignment-field-values/${this.configuration.encodeParam({ name: 'assignmentId', value: assignmentId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAssignmentFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getAssignmentFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getAssignmentFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetAssignmentFieldValuesBySeasonRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getAssignmentFieldValuesBySeasonRestructuringReporting.');
    }
    const assignmentId = requestParameters?.assignmentId;
    if (assignmentId === null || assignmentId === undefined) {
      throw new Error('Required parameter assignmentId was null or undefined when calling getAssignmentFieldValuesBySeasonRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/assignment-field-values/${this.configuration.encodeParam({ name: 'assignmentId', value: assignmentId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCalculationByRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetCalculationByRequestRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringCalculationRestructuringCalculationModel>;
  public getCalculationByRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetCalculationByRequestRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringCalculationRestructuringCalculationModel>>;
  public getCalculationByRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetCalculationByRequestRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringCalculationRestructuringCalculationModel>>;
  public getCalculationByRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetCalculationByRequestRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling getCalculationByRequestRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/calculation`;
    return this.httpClient.request<RestructuringCalculationRestructuringCalculationModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDashboardRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetDashboardRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringRequestsRestructuringDashboardModel>;
  public getDashboardRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetDashboardRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringRequestsRestructuringDashboardModel>>;
  public getDashboardRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetDashboardRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringRequestsRestructuringDashboardModel>>;
  public getDashboardRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetDashboardRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const searchString = requestParameters?.searchString;
    const fiscalYear = requestParameters?.fiscalYear;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryState = requestParameters?.queryState;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryCompanyManagerGid = requestParameters?.queryCompanyManagerGid;
    const queryCreatedByGid = requestParameters?.queryCreatedByGid;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (fiscalYear !== undefined && fiscalYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiscalYear, 'FiscalYear');
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryOrgCodeStartsWith) {
      queryOrgCodeStartsWith.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCodeStartsWith');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryCompanyManagerGid) {
      queryCompanyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerGid');
      });
    }
    if (queryCreatedByGid) {
      queryCreatedByGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CreatedByGid');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/dashboard`;
    return this.httpClient.request<RestructuringRequestsRestructuringDashboardModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getFieldValuesByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getFieldValuesByCountryRestructuringCommitment.');
    }
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling getFieldValuesByCountryRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/field-values/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getFieldValuesByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesByCountryRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getFieldValuesByCountryRestructuringReporting.');
    }
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling getFieldValuesByCountryRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/field-values/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getFieldValuesBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getFieldValuesBySeasonRestructuringCommitment.');
    }
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling getFieldValuesBySeasonRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/field-values/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getFieldValuesBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetFieldValuesBySeasonRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getFieldValuesBySeasonRestructuringReporting.');
    }
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling getFieldValuesBySeasonRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/field-values/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHeadersRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<RestructuringReportingRestructuringReportingHeaderModel>>;
  public getHeadersRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<RestructuringReportingRestructuringReportingHeaderModel>>>;
  public getHeadersRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<RestructuringReportingRestructuringReportingHeaderModel>>>;
  public getHeadersRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getHeadersRestructuringCommitment.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/headers/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<Array<RestructuringReportingRestructuringReportingHeaderModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHeadersRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<RestructuringReportingRestructuringReportingHeaderModel>>;
  public getHeadersRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<RestructuringReportingRestructuringReportingHeaderModel>>>;
  public getHeadersRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<RestructuringReportingRestructuringReportingHeaderModel>>>;
  public getHeadersRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetHeadersRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getHeadersRestructuringReporting.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/headers/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<Array<RestructuringReportingRestructuringReportingHeaderModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByCountryRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getItemsByCountryRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByCountryRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByCountryRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const countryCode = requestParameters?.countryCode;
    if (countryCode === null || countryCode === undefined) {
      throw new Error('Required parameter countryCode was null or undefined when calling getItemsByCountryRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/country/${this.configuration.encodeParam({ name: 'countryCode', value: countryCode, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsByRequestRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsByRequestRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByRequestRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByRequestRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling getItemsByRequestRestructuringCommitment.');
    }
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsByRequestRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsByRequestRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByRequestRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsByRequestRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsByRequestRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling getItemsByRequestRestructuringReporting.');
    }
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringCommitmentRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringCommitmentRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringCommitmentRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsBySeasonRestructuringCommitment(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringCommitmentRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getItemsBySeasonRestructuringCommitment.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/commitment/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getItemsBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringReportingRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>;
  public getItemsBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringReportingRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringReportingRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>>;
  public getItemsBySeasonRestructuringReporting(
    requestParameters?: RestructuringCustomerServiceGetItemsBySeasonRestructuringReportingRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const seasonId = requestParameters?.seasonId;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getItemsBySeasonRestructuringReporting.');
    }
    const searchString = requestParameters?.searchString;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;
    const queryAttributes = requestParameters?.queryAttributes;
    const queryAttributeByName = requestParameters?.queryAttributeByName;
    const queryAssignments = requestParameters?.queryAssignments;
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryRequestState = requestParameters?.queryRequestState;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryAre = requestParameters?.queryAre;
    const queryCountry = requestParameters?.queryCountry;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryReportingDate = requestParameters?.queryReportingDate;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const querySeasonId = requestParameters?.querySeasonId;
    const querySeasonTitle = requestParameters?.querySeasonTitle;
    const queryPlanTitle = requestParameters?.queryPlanTitle;
    const querySubState = requestParameters?.querySubState;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (queryAttributeByName !== undefined && queryAttributeByName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributeByName, 'Query.AttributeByName');
    }
    if (queryAssignments !== undefined && queryAssignments !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAssignments, 'Query.Assignments');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFirstName !== undefined && queryFirstName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstName, 'Query.FirstName');
    }
    if (queryLastName !== undefined && queryLastName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastName, 'Query.LastName');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryRequestState) {
      queryRequestState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RequestState');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryReportingDate !== undefined && queryReportingDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReportingDate, 'Query.ReportingDate');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (querySeasonTitle) {
      querySeasonTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonTitle');
      });
    }
    if (queryPlanTitle) {
      queryPlanTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanTitle');
      });
    }
    if (querySubState) {
      querySubState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SubState');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/reporting/seasons/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SharedPageModelOfRestructuringReportingRestructuringRequestReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetRequestRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringRequestsRestructuringRequestModel>;
  public getRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetRequestRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringRequestsRestructuringRequestModel>>;
  public getRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetRequestRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringRequestsRestructuringRequestModel>>;
  public getRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceGetRequestRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling getRequestRestructuringRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<RestructuringRequestsRestructuringRequestModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryAttributes(
    requestParameters?: RestructuringCustomerServiceQueryAttributesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<string>>;
  public queryAttributes(
    requestParameters?: RestructuringCustomerServiceQueryAttributesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<string>>>;
  public queryAttributes(
    requestParameters?: RestructuringCustomerServiceQueryAttributesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<string>>>;
  public queryAttributes(
    requestParameters?: RestructuringCustomerServiceQueryAttributesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling queryAttributes.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/attributes/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/values`;
    return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryCommentsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryCommentsRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringCommentsRestructuringCommentModel>;
  public queryCommentsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryCommentsRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringCommentsRestructuringCommentModel>>;
  public queryCommentsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryCommentsRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringCommentsRestructuringCommentModel>>;
  public queryCommentsRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryCommentsRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const requestId = requestParameters?.requestId;
    if (requestId === null || requestId === undefined) {
      throw new Error('Required parameter requestId was null or undefined when calling queryCommentsRestructuringRequest.');
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/${this.configuration.encodeParam({ name: 'requestId', value: requestId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/comments`;
    return this.httpClient.request<SharedPageModelOfRestructuringCommentsRestructuringCommentModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceQueryEmployeeSnapshotsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel>;
  public queryEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceQueryEmployeeSnapshotsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel>>;
  public queryEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceQueryEmployeeSnapshotsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel>>;
  public queryEmployeeSnapshots(
    requestParameters?: RestructuringCustomerServiceQueryEmployeeSnapshotsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const searchString = requestParameters?.searchString;
    const includeManagerInformation = requestParameters?.includeManagerInformation;
    const includeAttributes = requestParameters?.includeAttributes;
    const includeLetter = requestParameters?.includeLetter;
    const queryCountry = requestParameters?.queryCountry;
    const queryLegalEntity = requestParameters?.queryLegalEntity;
    const queryContractStatus = requestParameters?.queryContractStatus;
    const queryGid = requestParameters?.queryGid;
    const queryFullName = requestParameters?.queryFullName;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryAttributes = requestParameters?.queryAttributes;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (includeManagerInformation !== undefined && includeManagerInformation !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>includeManagerInformation, 'IncludeManagerInformation');
    }
    if (includeAttributes !== undefined && includeAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>includeAttributes, 'IncludeAttributes');
    }
    if (includeLetter !== undefined && includeLetter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>includeLetter, 'IncludeLetter');
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryLegalEntity) {
      queryLegalEntity.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LegalEntity');
      });
    }
    if (queryContractStatus) {
      queryContractStatus.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ContractStatus');
      });
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'Query.Gid');
    }
    if (queryFullName !== undefined && queryFullName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFullName, 'Query.FullName');
    }
    if (queryOrgCode !== undefined && queryOrgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCode, 'Query.OrgCode');
    }
    if (queryAttributes !== undefined && queryAttributes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAttributes, 'Query.Attributes');
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/employees`;
    return this.httpClient.request<SharedPageModelOfRestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValueRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public queryFieldValueRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public queryFieldValueRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public queryFieldValueRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling queryFieldValueRestructuringRequest.');
    }
    const searchString = requestParameters?.searchString;
    const fiscalYear = requestParameters?.fiscalYear;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryState = requestParameters?.queryState;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryCompanyManagerGid = requestParameters?.queryCompanyManagerGid;
    const queryCreatedByGid = requestParameters?.queryCreatedByGid;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (fiscalYear !== undefined && fiscalYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiscalYear, 'FiscalYear');
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryOrgCodeStartsWith) {
      queryOrgCodeStartsWith.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCodeStartsWith');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryCompanyManagerGid) {
      queryCompanyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerGid');
      });
    }
    if (queryCreatedByGid) {
      queryCreatedByGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CreatedByGid');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/field-values/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValueWithIdRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueWithIdRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSharedFieldItemModel>;
  public queryFieldValueWithIdRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueWithIdRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSharedFieldItemModel>>;
  public queryFieldValueWithIdRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueWithIdRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSharedFieldItemModel>>;
  public queryFieldValueWithIdRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryFieldValueWithIdRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const fieldName = requestParameters?.fieldName;
    if (fieldName === null || fieldName === undefined) {
      throw new Error('Required parameter fieldName was null or undefined when calling queryFieldValueWithIdRestructuringRequest.');
    }
    const searchString = requestParameters?.searchString;
    const fiscalYear = requestParameters?.fiscalYear;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryState = requestParameters?.queryState;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryCompanyManagerGid = requestParameters?.queryCompanyManagerGid;
    const queryCreatedByGid = requestParameters?.queryCreatedByGid;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (fiscalYear !== undefined && fiscalYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiscalYear, 'FiscalYear');
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryOrgCodeStartsWith) {
      queryOrgCodeStartsWith.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCodeStartsWith');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryCompanyManagerGid) {
      queryCompanyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerGid');
      });
    }
    if (queryCreatedByGid) {
      queryCreatedByGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CreatedByGid');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests/field-values-with-id/${this.configuration.encodeParam({ name: 'fieldName', value: fieldName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSharedFieldItemModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfRestructuringRequestsRestructuringRequestModel>;
  public queryRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfRestructuringRequestsRestructuringRequestModel>>;
  public queryRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfRestructuringRequestsRestructuringRequestModel>>;
  public queryRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceQueryRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const searchString = requestParameters?.searchString;
    const fiscalYear = requestParameters?.fiscalYear;
    const queryCountry = requestParameters?.queryCountry;
    const queryAre = requestParameters?.queryAre;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryPlanId = requestParameters?.queryPlanId;
    const queryState = requestParameters?.queryState;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryFiscalYear = requestParameters?.queryFiscalYear;
    const queryCompanyManagerGid = requestParameters?.queryCompanyManagerGid;
    const queryCreatedByGid = requestParameters?.queryCreatedByGid;
    const pageablePage = requestParameters?.pageablePage;
    const pageableSize = requestParameters?.pageableSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (fiscalYear !== undefined && fiscalYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiscalYear, 'FiscalYear');
    }
    if (queryCountry) {
      queryCountry.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Country');
      });
    }
    if (queryAre) {
      queryAre.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Are');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryPlanId) {
      queryPlanId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlanId');
      });
    }
    if (queryState) {
      queryState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.State');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryOrgCodeStartsWith) {
      queryOrgCodeStartsWith.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCodeStartsWith');
      });
    }
    if (queryFiscalYear) {
      queryFiscalYear.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FiscalYear');
      });
    }
    if (queryCompanyManagerGid) {
      queryCompanyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CompanyManagerGid');
      });
    }
    if (queryCreatedByGid) {
      queryCreatedByGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CreatedByGid');
      });
    }
    if (pageablePage !== undefined && pageablePage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageablePage, 'Pageable.Page');
    }
    if (pageableSize !== undefined && pageableSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageableSize, 'Pageable.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests`;
    return this.httpClient.request<SharedPageModelOfRestructuringRequestsRestructuringRequestModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveCalculationRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceSaveCalculationRestructuringCalculationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringCalculationRestructuringCalculationModel>;
  public saveCalculationRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceSaveCalculationRestructuringCalculationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringCalculationRestructuringCalculationModel>>;
  public saveCalculationRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceSaveCalculationRestructuringCalculationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringCalculationRestructuringCalculationModel>>;
  public saveCalculationRestructuringCalculation(
    requestParameters?: RestructuringCustomerServiceSaveCalculationRestructuringCalculationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringCalculationSaveRestructuringCalculationModel = requestParameters?.restructuringCalculationSaveRestructuringCalculationModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/calculation`;
    return this.httpClient.request<RestructuringCalculationRestructuringCalculationModel>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringCalculationSaveRestructuringCalculationModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceUpdateRequestRestructuringRequestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<RestructuringRequestsRestructuringRequestModel>;
  public updateRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceUpdateRequestRestructuringRequestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<RestructuringRequestsRestructuringRequestModel>>;
  public updateRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceUpdateRequestRestructuringRequestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<RestructuringRequestsRestructuringRequestModel>>;
  public updateRequestRestructuringRequest(
    requestParameters?: RestructuringCustomerServiceUpdateRequestRestructuringRequestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const restructuringRequestsUpdateRestructuringRequestModel = requestParameters?.restructuringRequestsUpdateRestructuringRequestModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/restructuring/customer/v1/requests`;
    return this.httpClient.request<RestructuringRequestsRestructuringRequestModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: restructuringRequestsUpdateRestructuringRequestModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
