import { Injectable } from '@angular/core';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { finalize, map, Observable, Subject, tap } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { Employee } from '@coin/shared/util-models';
import { IncentivePartnerService, IncentiveSeason } from '../generated';

interface IncentiveSupportStateModel {
  selectedSeason: IncentiveSeason;
  availableSeasons: IncentiveSeason[];
}

const initialState = (): IncentiveSupportStateModel => ({
  selectedSeason: undefined,
  availableSeasons: []
});

@Injectable()
export class IncentiveSupportComponentState extends ImmerComponentStore<IncentiveSupportStateModel> {
  public selectedSeason$ = this.select(({ selectedSeason }) => selectedSeason);
  public availableSeasons$ = this.select(({ availableSeasons }) => availableSeasons);

  // header events
  sortingChange$ = new Subject<void>();
  setFilter$ = new Subject<void>();
  setSearchItem$ = new Subject<Employee>();

  constructor(
    private httpHelperService: HttpHelpersService,
    private incentivePartnerService: IncentivePartnerService,
    private loadingService: LoadingService,
    private router: Router
  ) {
    super(initialState());
  }

  public loadSeasonsAndSelect(seasonId?: string): Observable<IncentiveSeason[]> {
    this.loadingService.present();
    return this.incentivePartnerService
      .getSeasonsIncentivePartnerV1({
        pagingSize: 50
      })
      .pipe(
        this.httpHelperService.withStatusMessages({ error: 'incentive-support.errors.cannot-load-season' }),
        map(seasonsPage => seasonsPage.content),
        tap(seasons => {
          this.setState(state => {
            state.availableSeasons = seasons;
          });
        }),
        map(seasons => (seasonId ? seasons.filter(season => season.id === seasonId) : seasons)),
        finalize(() => {
          this.loadingService.dismiss();
        })
      );
  }

  public setSelectedSeason(season: IncentiveSeason): void {
    this.setState(state => {
      state.selectedSeason = season;
    });
    this.router.navigate([], { queryParams: { selectedSeasonId: season.id }, queryParamsHandling: 'merge' });
  }

  public getSelectedSeason(): IncentiveSeason {
    return this.get().selectedSeason;
  }

  public reset(): void {
    this.setState(state => {
      Object.assign(state, initialState());
    });
  }
}
