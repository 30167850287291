import { Component, EventEmitter, Input, model, Output, TemplateRef } from '@angular/core';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { ListColumn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-c-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent {
  @Input() leftTemplate: TemplateRef<unknown>;
  @Input() rightTemplate: TemplateRef<unknown>;
  @Input() columns: ListColumn[];
  @Input() translationPrefix: string;
  @Input() background: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAction: EventEmitter<string> = new EventEmitter<string>();
  public scrollbarWidth = model(15);

  public get isTranslationPrefixGiven(): boolean {
    return this.translationPrefix?.trim().length > 0;
  }

  public get columnWidths(): string[] {
    return this.columns?.map(column => column.width);
  }

  public get firstNamedIndex(): number {
    return this.columns.findIndex(column => !!column.name);
  }

  public get lastNamedIndex(): number {
    return TinyHelpers.findLastIndex(this.columns, column => !!column.name);
  }

  public pascalcaseToKebabCase = TinyHelpers.pascalcaseToKebabCase;

  public onActionClick(action: string): void {
    this.onAction.emit(action);
  }
}
